const AlertCode = {
  FETCH_TABLE_DATA: 'Error Fetching Table Data',
  FETCH_ROW_COUNT: 'Error Fetching Row Count',
  FETCH_API_LIST: 'Error Fetching Api List',
  FETCH_RECORDS: 'Error Fetching Record',
  FETCH_USER: 'Error Fetching User',
  FETCH_USER_CREDENTIALS: 'Error Fetching User Credentials',
  FETCH_DEMO_TOKEN: 'Error Fetching Demo token',
  FETCH_DOC_TOKEN: 'Error Fetching Documentation Token',
  FETCH_INSTATUS_PAGE_LINK: 'Error Fetching Instatus Page Link',
  ERROR_IN_SENDING_EMAIL: 'Error in sending email',
  INVALID_DEMO_URL: 'Invalid Demo URL',
  INVALID_LOGIN_CREDENTIALS: 'Incorrect email or password',
  FETCH_ANALYTIC_URL: 'Error Fetching Analytics',
  LOGIN: 'Please Login Again',
  UPDATE_RECORDS: 'Could not update. Please try again',
  COPIED_TO_CLIPBOARD: 'Copied To Clipboard',
  UNAUTHORIZED: 'Unauthorized',
  SIGNUP_FAILED: 'Signup Failed',
  ERROR_FETCHING_GOOGLE_LOGIN_URL: 'Error fetching google login url',
  ERROR_FETCHING_WORKFLOWS: 'Error fetching workflows',
  ERROR_FETCHING_APPLICATION_ASSIGNMENT: 'Error fetching application assignment config',
  ERROR_FETCHING_WORKFLOW: 'Error fetching workflow',
  ERROR_FETCHING_MODULES: 'Error fetching modules',
  ERROR_FETCHING_COUNTRIES: 'Error fetching countries',
  ERROR_UPLOADING_WORKFLOW: 'Error Uploading Workflow',
  SOMETHING_WENT_WRONG: 'Something went wrong',
  ALL_DONE: 'All Done',
  PASSWORD_MISMATCH: 'The passwords don’t match',
  INVALID_PASSWORD_LEN: 'password length should be 8 characters with one uppercase letter one symbol and one number',
  INVALID_EMAIL: 'Invalid email',
  PASSWORD_CREATION_FAILED: 'Password Creation Failed',
  FETCH_PREVIEW: 'Error Fetching Application Preview',
  SET_NDA_AGREEMENT: 'Error Saving NDA Agreement status',
  EMAIL_SENDING_LIMIT_EXCEEDED: 'Please try sending again after 5 mins',
  ERROR_GENERATING_LINK: 'Error generating link',
  DUPLICATE_TRANSACTIONID_LINK: 'Link for the transaction Id was already generated',
  INVALID_TRANSACTION_ID: 'Transaction Id should not contain any special characters',
  NO_WORKFLOWS: 'No workflows found',
  usageLogs: {
    GENERATING: 'Please wait while we generate usage logs',
    GENERATING_TRY_AGAIN_LATER: 'Logs generation in process, please try again after sometime',
    DUPLICATE_REQUEST: 'Already generating usage logs, please wait',
    GENERATED: 'Successfully generated usage logs',
    EMPTY_DATA: 'No usage logs found',
    UNKNOWN_ERROR: 'Error fetching usage logs',
  },
  auditDataDownload: {
    GENERATING: 'Please wait while we generate audit data',
    DUPLICATE_REQUEST: 'Already generating audit data, please wait',
    GENERATED: 'Successfully generated audit data',
    EMPTY_DATA: 'No audit data to download',
    LIMIT_EXCEEDED: 'Download size limit exceeded',
    UNKNOWN_ERROR: 'Error downloading audit Data',
  },
  auth: {
    unauthorizedProduct: 'Unauthorized access to product',
    userDisabled: 'Your account has been disabled. Please contact your admin',
    companyInactive: 'This account is no longer active. Please contact your SPOC from HyperVerge',
  },
  PLATFORM_PRICING_DEATILS: 'Error while fetching the pricing details',
  ADD_COMMENTS: 'Failed to add comment',
  FETCH_COMMENTS: 'Failed to fetch comments',
  DOWNLOAD_CSV_EMPTY_DATA: 'No data to download for these filters',
  UPDATE_AML_TAGS_SUCCESS: 'Successfully updated AML tags',
  UPDATE_AML_TAGS_FAILURE: 'Error updating AML tags',
  UPDATE_AML_COMMENTS_SUCCESS: 'Successfully updated AML comment',
  UPDATE_AML_COMMENTS_FAILURE: 'Error updating AML comments',
  GET_AML_COMMENTS_FAILURE: 'Error getting AML comments',
  APPLICATION_STATUS_UPDATE_ERROR: 'Unable to update application status',
  ERROR_FETCHING_PRICE: 'Error fetching pricing for client',
  ERROR_FETCHING_USER: 'Error in fetching user',
  ERROR_FETCHING_USAGE_DATA: 'Error fetching usage data',
  ERROR_SENDING_OTP: 'Error in sending OTP',
  ERROR_AUTHENTICATE_USER: 'Error in authenticating user',
  ERROR_FETCHING_REVIEW_DECISIONS: 'Error in fetching review decisions from s3',
};
export default AlertCode;
