import React, { useState, useEffect } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/react';
import { useSelector, useDispatch } from 'react-redux';
import {
  get, reduce, set,
} from 'lodash';
import { useNavigate } from 'react-router-dom';
import { updateWorkflow } from '../../reducers/user';
import { updateComments } from '../../reducers/data';
import useShowErrorAlert, {
  useFormatAnalyticsData,
  useShowCustomAlert, useShowToastNotification, useFormatPerformanceAnalyticsData,
} from '../../utils/lib';
import AlertCode from '../../constants/alertCodes';
import { PERFORMANCE_METRIC_EVENTS, constants } from '../../config';
import RecordHeader from '../../components/Record/Header/RecordHeader';
import RecordContent from '../../components/Record/RecordContent';
import CustomAlert from '../../components/Common/CustomAlert';
import ToastNotification from '../../components/Common/ToastNotification';
import { getCommentsApi, addCommentApi } from '../../api/comments';
import { closeToastNotification } from '../../reducers/alert';
import AMLCheckSidePanel from '../../components/Record/RecordDetails/Panels/AMLCheckSidePanel';
import LinkIcon from '../../assests/icons/linkIcon.svg';
import SpeechBalloon from '../../assests/icons/speechBalloon.png';
import {
  initFlagData, initHitData, initHitsReviewTags,
  initModuleData, initRawRecordData, initRecordData, initReviewTags,
  initTransactionData, initV2FlagData, resetCurrentCredential, resetData, updateCurrentCredential,
  updateFailureReason,
  updateStatusHistory,
  updateTransactionData, updateReviewReasonsConfig,
} from '../../reducers/appDetails';
import useS3Config from '../../utils/hooks/s3Config';

import './Record.scss';
import { processRecordData } from '../../components/Record/RecordUtils';
import { isNonEmptyObject, isNumber, isNonEmptyArray } from '../../utils/helpers';
import rudderstackEvents from '../../constants/rudderstackEventNames';
import screenNames from '../../constants/screenNames';
import errorCode from '../../constants/errorCode';
import { getTransactionMetadataFilters } from '../../components/Main/Application/AuditFilterUtils';
import { updateReviewerApi } from '../../api/audit';

function Record({ pageConfig }) {
  const showErrorAlert = useShowErrorAlert();
  const showNotification = useShowToastNotification();
  const formatPerformanceAnalyticsData = useFormatPerformanceAnalyticsData();
  const showCustomAlert = useShowCustomAlert();
  const getFlagConfig = useS3Config('flagConfig');
  const transactionMetadataFilters = useSelector(
    (state) => state?.data?.transactionMetadataFilters,
  );

  const [recordTitle, setRecordTitle] = useState('');
  const [loading, setLoading] = useState(true);
  const [toastMsg, setToastMsg] = useState('');
  const [toastIcon, setToastIcon] = useState(null);
  const [showTimelineView, setShowTimelineView] = useState(false);
  const [workflowModulesData, setWorkflowModulesData] = useState([]);

  const currentAppId = useSelector((s) => s.user.credState?.current?.appId);
  const workflow = useSelector((s) => s.user.workflow);
  const comments = useSelector((s) => s.data.comments);
  const userId = useSelector((s) => s.user.email);
  const clientId = useSelector((s) => s.user.clientId);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const formatAnalyticsData = useFormatAnalyticsData();
  const [selectedAssignee, setSelectedAssignee] = useState(null);
  const currentReviewerEmail = useSelector((state) => get(state, 'appDetails.transactionData.reviewerEmail', ''));
  const userEmail = useSelector((state) => state.user.email);

  const updateAssignee = (assignee) => {
    setSelectedAssignee(assignee);
  };

  const onConfirmManualAssignmentModalClose = () => {
    setSelectedAssignee(null);
  };

  const {
    pathname, recordId, urlRoute, recordType, filters, recordPage, searchId, pagination,
    recordNavigationUpdateData, tableData, recordIdData, transactionId, sortBy,
  } = pageConfig;
  const finalTransactionId = transactionId || recordId || recordIdData?.transactionId;

  const { currentElement, page } = pagination;

  const resetDataOnLoadFailure = () => {
    setRecordTitle(`${pageConfig.recordType === 'transactionId' ? 'Transaction ID:' : 'Request ID:'} ${recordId}`);
    dispatch(resetData());
  };

  const cacheAndReturnWorkflowModules = async (workflowId) => {
    if (workflow[`${currentAppId}_${workflowId}`]) return workflow[`${currentAppId}_${workflowId}`];
    let workflowModules = {};
    try {
      const getWorkflow = await axios({
        method: 'POST',
        url: `${process.env.REACT_APP_SERVER_URL}/api/v1/workflow/id`,
        headers: { appId: currentAppId },
        data: { workflowId },
      });
      workflowModules = reduce(
        getWorkflow.data.result.modules,
        (acc, { id, subType, name }) => ({ ...acc, [id]: { id, subType, name } }),
        {},
      );
      dispatch(updateWorkflow({
        workflowModules,
        appId: currentAppId,
        workflowId,
      }));
    } catch (err) {
      // Uncomment when backend 5XX is fixed
      // Sentry.captureException(err);
      console.log(err);
    }
    return workflowModules;
  };

  const fetchAppIdCredential = async () => {
    try {
      const credRes = await axios({
        method: 'GET',
        url: `${process.env.REACT_APP_SERVER_URL}/api/v1/user/credentials`,
      });
      const currentAppKey = credRes.data.result.credentials.find(
        ({ appId }) => appId === currentAppId,
      )?.appKey;
      dispatch(updateCurrentCredential({ appId: currentAppId, appKey: currentAppKey }));
    } catch (error) {
      Sentry.captureException(`${errorCode.APPLICATION_FETCH_CREDENTIAL_ERROR} - ${error}`, {
        extra: {
          errorMessage: AlertCode.FETCH_USER_CREDENTIALS,
        },
      });
      showErrorAlert({ error, message: AlertCode.FETCH_USER_CREDENTIALS });
      dispatch(resetCurrentCredential());
    }
  };

  const fetchReviewReasonsConfigFile = async (appId, workflowId) => {
    const fileUrl = `${process.env.REACT_APP_REVIEW_REASONS_CONFIG_BUCKET_PATH}${appId}/${workflowId}.json`;

    try {
      const response = await axios.get(fileUrl, {
        withCredentials: false,
      });
      const config = get(response, 'data.dashboardManualReviewConfig', {});
      dispatch(updateReviewReasonsConfig(config));
    } catch (error) {
      dispatch(updateReviewReasonsConfig({}));
      Sentry.captureException(`${errorCode.APPLICATION_REVIEW_DESCISIONS_CONFIG_FETCH_ERROR} - ${error}`, {
        extra: {
          errorMessage: AlertCode.ERROR_FETCHING_REVIEW_DECISIONS,
        },
      });
    }
  };

  const populateRecord = async () => {
    try {
      dispatch(updateReviewReasonsConfig({}));
      const res = await axios({
        method: 'POST',
        url: `${process.env.REACT_APP_SERVER_URL}/api/v1/${urlRoute}/id`,
        headers: { appId: currentAppId },
        data: recordIdData,
      });
      const {
        records,
        reason,
        status,
        createdAt,
        metaData,
        sdkError,
        userDetails,
        v2Flags,
        updatedAt,
        eventTime,
        statusHistory,
        failureReason,
        reviewerEmail,
      } = res.data.result;
      const { workflowId, name } = metaData || {};
      const workflowModules = workflowId ? await cacheAndReturnWorkflowModules(workflowId) : {};
      setWorkflowModulesData(workflowModules);
      const transactionData = {
        ...recordIdData,
        sdkError,
        createdAt,
        updatedAt,
        eventTime,
        status,
        reason,
        reviewerEmail,
        workflowId,
        ...(metaData || {}),
        userDetails: userDetails || {},
      };

      setRecordTitle(name || `${pageConfig.recordType === 'transactionId' ? 'Transaction ID:' : 'Request ID:'} ${recordId}`);
      const {
        flagByModule,
        v2FlagsByModule,
        modules,
        groupedRecordData,
        groupedHitData,
        reviewTagsConfigObj,
        hitsReviewTagsConfigObj,
      } = await processRecordData(
        workflowModules,
        transactionData,
        records,
        getFlagConfig,
        v2Flags,
      );
      await fetchReviewReasonsConfigFile(currentAppId, workflowId);
      dispatch(initTransactionData(transactionData));
      dispatch(initFlagData(flagByModule));
      dispatch(initV2FlagData(v2FlagsByModule));
      dispatch(initModuleData(modules));
      dispatch(initRawRecordData(records));
      dispatch(initRecordData(groupedRecordData));
      dispatch(initHitData(groupedHitData));
      dispatch(initReviewTags(reviewTagsConfigObj));
      dispatch(initHitsReviewTags(hitsReviewTagsConfigObj));
      dispatch(updateStatusHistory(statusHistory));
      dispatch(updateFailureReason(failureReason));
    } catch (error) {
      Sentry.captureException(`${errorCode.APPLICATION_FETCH_RECORD_ERROR} - ${error}`, {
        extra: {
          errorMessage: AlertCode.FETCH_RECORDS,
        },
      });
      resetDataOnLoadFailure();
      showErrorAlert({ error, message: AlertCode.FETCH_RECORDS });
    }
  };

  const updateApplicationReviewer = async () => {
    const payload = {
      transactionIds: [finalTransactionId],
      selectedReviewerEmail: selectedAssignee || userEmail,
      appId: currentAppId,
      updateAll: 'no',
    };
    await updateReviewerApi(payload);
  };

  const onAssigneeChange = async () => {
    try {
      if (currentReviewerEmail === selectedAssignee) {
        showErrorAlert({ message: `The application is already been assigned to ${selectedAssignee}` });
        setSelectedAssignee(null);
        return;
      }
      setLoading(true);
      await updateApplicationReviewer();
      await populateRecord();
      setSelectedAssignee(null);
      setLoading(false);
      setToastMsg('Reviewer updated successfully');
      setToastIcon(SpeechBalloon);
      showNotification();
      formatAnalyticsData(
        userId,
        clientId,
        rudderstackEvents.DASHBOARD_RECORD_REVIEWER_UPDATE,
        screenNames.RECORD,
      );
    } catch (err) {
      setSelectedAssignee(null);
      setLoading(false);
      showErrorAlert({ err, message: 'error occured while assigning an reviewer to this application' });
    }
  };

  const fetchComments = async () => {
    try {
      const transaction = recordIdData.transactionId || transactionId;
      const commentRes = await getCommentsApi(currentAppId, transaction);
      dispatch(updateComments(commentRes));
    } catch (err) {
      Sentry.captureException(`${errorCode.APPLICATION_FETCH_COMMENT_ERROR} - ${err}`, {
        extra: {
          errorMessage: AlertCode.FETCH_COMMENTS,
        },
      });
      showErrorAlert({ err, message: AlertCode.FETCH_COMMENTS });
    }
  };

  const handleRecordNavigation = async (nextPage) => {
    const formattedTransactionMetadataFilters = getTransactionMetadataFilters(
      transactionMetadataFilters,
    );
    const dataRes = await axios({
      method: 'POST',
      url: `${process.env.REACT_APP_SERVER_URL}/api/v1/${urlRoute}/filter`,
      headers: { appId: currentAppId },
      data: {
        ...filters,
        ...(isNonEmptyObject(sortBy) ? { sortBy } : {}),
        page: nextPage,
        transactionMetadataFilters: formattedTransactionMetadataFilters,
      },
    });

    const data = get(dataRes, 'data.result.data', []);
    if (!data.length) {
      return data;
    }
    recordNavigationUpdateData(data, nextPage);
    return data;
  };
  const updateNewRecordId = async (newRecordId, nextRecord) => {
    navigate(`${pathname}?${recordType}=${newRecordId}`, {
      state: {
        currentElement: nextRecord,
      },
    });
  };
  const loadPrevRecord = async () => {
    const loadPrevRecordStartTime = performance.now();
    let statusCode = 200;
    let nextRecord = currentElement - 1;
    let records = tableData;
    try {
      if (
        nextRecord === records.length
        || records.length === 0
        || nextRecord < 0
      ) {
        // all records have been encountered for the page,
        if (page === 0) {
          if (records.length < constants.maxRecordsInTable || nextRecord < 0) {
            showCustomAlert({
              message: AlertCode.ALL_DONE,
              alertSeverity: 'success',
            });
            return;
          }
        }
        // fetch records for next page
        const dataRes = await handleRecordNavigation(page - 1);
        if (dataRes.length === 0) {
          showCustomAlert({
            message: AlertCode.ALL_DONE,
            alertSeverity: 'success',
          });
          return;
        }
        records = dataRes;
        nextRecord = constants.maxRecordsInTable - 1;
      }
    } catch (err) {
      statusCode = err?.response?.status || 400;
      Sentry.captureException(`${errorCode.APPLICATION_LOAD_PREV_ERROR} - ${err}`);
    }
    const processingTime = performance.now() - loadPrevRecordStartTime;
    const eventName = PERFORMANCE_METRIC_EVENTS.APPLICATION_LIST_LOAD_PREVIOUS_RECORD;
    const eventObj = {
      processingTime,
      statusCode,
    };
    formatPerformanceAnalyticsData(eventObj, eventName);
    updateNewRecordId(records[nextRecord][recordType], nextRecord);
    formatAnalyticsData(
      userId,
      clientId,
      rudderstackEvents.DASHBOARD_RECORD_NAVIGATION_CLICK,
      screenNames.RECORD,
    );
  };

  const loadNextRecord = async () => {
    const loadNextRecordStartTime = performance.now();
    let statusCode = 200;
    let nextRecord = currentElement + 1;
    let records = tableData;
    if (nextRecord === records.length || records.length === 0) {
      // all records have been encountered for the page,
      if (records.length < constants.maxRecordsInTable) {
        showCustomAlert({
          message: AlertCode.ALL_DONE,
          alertSeverity: 'success',
        });
        return;
      }
      // fetch records for next page
      try {
        const dataRes = await handleRecordNavigation(page + 1);
        if (dataRes.length === 0) {
          showCustomAlert({
            message: AlertCode.ALL_DONE,
            alertSeverity: 'success',
          });
          return;
        }
        records = dataRes;
        nextRecord = 0;
      } catch (err) {
        statusCode = err?.response?.status || 400;
        Sentry.captureException(`${errorCode.APPLICATION_LOAD_NEXT_ERROR} - ${err}`);
      }
    }
    const processingTime = performance.now() - loadNextRecordStartTime;
    const eventName = PERFORMANCE_METRIC_EVENTS.APPLICATION_LIST_LOAD_NEXT_RECORD;
    const eventObj = {
      processingTime,
      statusCode,
    };
    formatPerformanceAnalyticsData(eventObj, eventName);
    updateNewRecordId(records[nextRecord][recordType], nextRecord);
    formatAnalyticsData(
      userId,
      clientId,
      rudderstackEvents.DASHBOARD_RECORD_NAVIGATION_CLICK,
      screenNames.RECORD,
    );
  };

  const onActionSelect = async (actionValue) => {
    if (actionValue === 'share_application') {
      navigator.clipboard.writeText(`${window.location.origin}?recordPage=${recordPage}&recordIdType=${recordType}&recordId=${recordId}&appid=${currentAppId}`);
      setToastMsg('Link to this application is copied to clipboard. Please share it');
      setToastIcon(LinkIcon);
      showNotification();
    }
  };

  const updateApplicationStatus = async (action, reviewDecisions) => {
    // map button value to application status value
    let eventName;
    const updateStatusStartTime = performance.now();
    const eventObj = {
      APIEndPoint: 'api/v1/audit/id',
      metaData: {
        appId: currentAppId,
        transactionId: recordId,
      },
    };
    try {
      eventName = PERFORMANCE_METRIC_EVENTS.APPLICATION_LIST_APPROVE_CLICK;
      await axios({
        method: 'PUT',
        url: `${process.env.REACT_APP_SERVER_URL}/api/v1/audit/id`,
        headers: { appId: currentAppId },
        data: {
          status: action, transactionId: recordId, reason: '', ...(isNonEmptyArray(reviewDecisions) && { reviewDecisionReasons: reviewDecisions }),
        },
      });

      const payload = {};
      if (action === 'manually_approved') {
        dispatch(updateTransactionData({
          status: action,
        }));
        setToastMsg('Approved application successfully');
        showNotification();
        formatAnalyticsData(
          userId,
          clientId,
          rudderstackEvents.DASHBOARD_RECORD_APPROVED_CLICK,
          screenNames.RECORD,
          action,
        );
      } else if (action === 'manually_declined') {
        eventName = PERFORMANCE_METRIC_EVENTS.APPLICATION_LIST_DECLINE_CLICK;
        dispatch(updateTransactionData({
          status: action,
        }));
        setToastMsg('Declined application successfully');
        showNotification();
        formatAnalyticsData(
          userId,
          clientId,
          rudderstackEvents.DASHBOARD_RECORD_DECLINED_CLICK,
          screenNames.RECORD,
          action,
        );
      } else {
        payload.message = 'Reported';
        payload.alertSeverity = 'warning';
      }
      set(eventObj, 'statusCode', 200);
      loadNextRecord();
    } catch (error) {
      if (isNumber(error?.response?.statusCode)) set(eventObj, 'statusCode', error?.response?.statusCode);
      Sentry.captureException(`${errorCode.APPLICATION_UPDATE_TRANSACTION_ERROR} - ${error}`, {
        extra: {
          errorMessage: AlertCode.UPDATE_RECORDS,
        },
      });
      showErrorAlert({ error, message: AlertCode.UPDATE_RECORDS });
    }
    const processingTime = performance.now() - updateStatusStartTime;
    set(eventObj, 'processingTime', processingTime);
    formatPerformanceAnalyticsData(eventObj, eventName);
  };

  const addNewComment = async (comment) => {
    try {
      const transaction = recordIdData.transactionId || transactionId;
      const commentRes = await addCommentApi(
        comment, userId, transaction, currentAppId,
      );
      dispatch(updateComments(commentRes));
      setToastMsg('Successfully added you comment');
      setToastIcon(SpeechBalloon);
      showNotification();
      formatAnalyticsData(
        userId,
        clientId,
        rudderstackEvents.DASHBOARD_RECORD_COMMENT_ADDED,
        screenNames.RECORD,
      );
    } catch (err) {
      Sentry.captureException(`${errorCode.APPLICATION_ADD_COMMMENT_ERROR} - ${err}`, {
        extra: {
          errorMessage: AlertCode.ADD_COMMENTS,
        },
      });
      showErrorAlert({ err, message: AlertCode.ADD_COMMENTS });
    }
  };

  const reAssignAndUpdateApplicationStatus = async (action, decisions) => {
    try {
      const assignee = selectedAssignee || userEmail;
      const statusMap = {
        manually_approved: 'approved',
        manually_declined: 'declined',
      };
      setLoading(true);
      await updateApplicationReviewer();
      await updateApplicationStatus(action, decisions);
      await populateRecord();
      setSelectedAssignee(null);
      setLoading(false);
      setToastMsg(`Application assigned to ${assignee} and ${statusMap[action]} successfully`);
      setToastIcon(SpeechBalloon);
      showNotification();
    } catch (error) {
      setLoading(false);
      showErrorAlert({ error, message: AlertCode.UPDATE_APPLICATION_STATUS });
      Sentry.captureException(`${errorCode.APPLICATION_STATUS_UPDATE_ERROR} - ${error}`, {
        extra: {
          errorMessage: AlertCode.UPDATE_APPLICATION_STATUS,
        },
      });
    }
  };

  const renderNotification = () => (
    <ToastNotification
      imageComponentRenderer={() => (<img className="actions_icon" src={toastIcon} alt="" />)}
      message={toastMsg}
    />
  );

  const initializeRecord = async () => {
    const initRecordStartTime = performance.now();
    setLoading(true);
    await Promise.all([fetchAppIdCredential(), populateRecord(), fetchComments()]);
    formatAnalyticsData(
      userId,
      clientId,
      rudderstackEvents.DASHBOARD_RECORD_LAUNCH,
      screenNames.RECORD,
    );
    const processingTime = performance.now() - initRecordStartTime;
    const eventName = PERFORMANCE_METRIC_EVENTS.APPLICATION_DETAILS_PAGE_INIT_FETCH;
    const eventObj = {
      processingTime,
      statusCode: 200,
      metaData: {
        appId: currentAppId,
      },
    };
    formatPerformanceAnalyticsData(eventObj, eventName);
    setLoading(false);
  };

  useEffect(() => {
    initializeRecord();
  }, [recordId]);

  useEffect(() => () => {
    dispatch(closeToastNotification());
  }, []);

  return (
    <div id="record__container">
      <AMLCheckSidePanel />
      <RecordHeader
        loading={loading}
        pageConfig={pageConfig}
        recordTitle={recordTitle}
        currentPage={page}
        loadPrevRecord={loadPrevRecord}
        loadNextRecord={loadNextRecord}
        onActionSelect={onActionSelect}
        searchId={searchId}
        showTimelineView={showTimelineView}
        setShowTimelineView={setShowTimelineView}
      />
      <RecordContent
        loading={loading}
        comments={comments}
        addNewComment={addNewComment}
        updateApplicationStatus={updateApplicationStatus}
        showTimelineView={showTimelineView}
        workflowModulesData={workflowModulesData}
        onChangeAssigneeModalClose={onConfirmManualAssignmentModalClose}
        selectedAssignee={selectedAssignee}
        onSelectAssignee={updateAssignee}
        onAssigneeChange={onAssigneeChange}
        onReAssignment={reAssignAndUpdateApplicationStatus}
      />
      <CustomAlert />
      {renderNotification()}
    </div>
  );
}

export default Record;

Record.propTypes = {
  pageConfig: PropTypes.bool.isRequired,
};
