import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { CommonTable } from 'clm-components';
import { useSelector } from 'react-redux';
import EmptyState from './EmptyState';
import { constants } from '../../../config';
import { genTableData, genTableHeaders } from './AuditTableUtils';
import useGetUserPermissions from '../../../Permissions/hooks';
import checkForManualAssignmentPermissions from '../../ManualAssignment/utils';

function DataTable({
  handleRowClick,
  handlePageChange,
  tableColumns,
  isLoading,
  data,
  page,
  totalElements,
  handleSelectHeadState,
  handleRowCheckBoxChange,
}) {
  const getUserPermissions = useGetUserPermissions();

  const { allowedPermissions } = getUserPermissions;

  const rowsPerPage = constants.maxRecordsInTable;
  const transactionMetadataColumnsConfig = useSelector(
    (state) => state.s3Config?.transactionMetadataColumnsConfig?.configData,
  );
  const dashboardDataTableColumns = useSelector(
    (state) => state.data?.dashboardDataTableColumns,
  );
  const selectedTransactionsForManualAssignment = useSelector((state) => state
    .data.selectedTransactionsForManualAssignment || {});
  const applicationManualReviewAssignment = useSelector((state) => state
    ?.config?.applicationManualReviewAssignment);
  const selectHeadState = useSelector((state) => state?.data?.selectHeadStateForApplicationsTable);
  const [tableData, setTableData] = useState(
    genTableData(data, transactionMetadataColumnsConfig,
      selectedTransactionsForManualAssignment, selectHeadState, dashboardDataTableColumns),
  );
  const updatedTableColumns = genTableHeaders(tableColumns);

  useEffect(() => {
    setTableData(genTableData(data,
      transactionMetadataColumnsConfig,
      selectedTransactionsForManualAssignment,
      selectHeadState,
      dashboardDataTableColumns));
  }, [data, transactionMetadataColumnsConfig,
    selectedTransactionsForManualAssignment, selectHeadState, dashboardDataTableColumns]);

  const getTrueKey = (headState) => Object.keys(headState).find((key) => headState[key] === true);

  return (
    <>
      <CommonTable
        data-hj-suppress
        id="application__data_table__table"
        tableHeader={updatedTableColumns}
        tableData={tableData}
        isLoading={isLoading}
        pageNumber={page}
        pageLength={rowsPerPage}
        totalRows={totalElements}
        handlePageChange={handlePageChange}
        emptyStateComponent={<EmptyState />}
        onRowClick={handleRowClick}
        handleSelectHeadOption={handleSelectHeadState}
        handleRowCheckBoxChange={handleRowCheckBoxChange}
        headState={getTrueKey(selectHeadState.headState)}
        selectOptions={constants.TABLE_HEADOPTIONS_FOR_DATATABLE}
        enableSelect={checkForManualAssignmentPermissions(
          allowedPermissions,
          constants.PERMISSIONS_TO_ENABLE_MANUAL_ASSIGMENT,
          [
            {
              key: applicationManualReviewAssignment,
              value: 'none',
            },
          ],
        )}
      />
    </>
  );
}

export default DataTable;

DataTable.propTypes = {
  handlePageChange: PropTypes.func.isRequired,
  handleRowClick: PropTypes.func.isRequired,
  tableColumns: PropTypes.arrayOf(PropTypes.any).isRequired,
  isLoading: PropTypes.bool.isRequired,
  data: PropTypes.arrayOf(PropTypes.any).isRequired,
  page: PropTypes.number.isRequired,
  totalElements: PropTypes.number.isRequired,
  handleSelectHeadState: PropTypes.func.isRequired,
  handleRowCheckBoxChange: PropTypes.func.isRequired,
};
