import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { isNil, get } from 'lodash';
import smallArrow from '../../../assests/icons/smallArrow.svg';

const ModalBody = ({
  assigneeEmail,
}) => {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [previouslyAssignedApplicationsWithValidCount,
    setPreviouslyAssignedApplicationsWithValidCount] = useState({});
  const previouslyAssignedApplications = useSelector((state) => get(state, 'data.previouslyAssignedReviewersList', []));
  const transactionCountData = useSelector((state) => get(state, 'data.assignedTransactionCountData', {}));
  const isSelectAll = useSelector((state) => get(state, 'data.selectHeadStateForApplicationsTable.headState.all', false));
  const [unassignedApplicationsCountIfSelectIsNotAll,
    setUnassignedApplicationsCountIfSelectIsNotAll] = useState(0);
  const [previouslyAssignedApplicationsCountIfSelectIsNotAll,
    setPreviouslyAssignedApplicationsCountIfSelectIsNotAll] = useState(0);
  const auditData = useSelector((state) => state.data.value);
  const totalApplications = get(transactionCountData, 'total', '0');
  const unassignedApplications = get(transactionCountData, 'unAssigned', '0');
  const selectedTransactionsForManualAssignment = Object.keys(useSelector((state) => get(state, 'data.selectedTransactionsForManualAssignment', {})));

  const getUnAssignedCountAndPreviouslyAssignedCountBreakDownIfNotSelectAll = () => {
    if (isSelectAll) return;

    const selectedTransactions = selectedTransactionsForManualAssignment
      .map((transactionId) => auditData
        .find((transaction) => transaction.transactionId === transactionId));

    const { unAssigned, assigned, breakdown } = selectedTransactions.reduce(
      (acc, transaction) => {
        if (!transaction?.reviewerEmail) {
          acc.unAssigned.push(transaction);
        } else {
          acc.assigned.push(transaction);
          acc.breakdown[transaction.reviewerEmail] = (acc
            .breakdown[transaction.reviewerEmail] || 0) + 1;
        }
        return acc;
      },
      { unAssigned: [], assigned: [], breakdown: {} },
    );

    setUnassignedApplicationsCountIfSelectIsNotAll(unAssigned.length);
    setPreviouslyAssignedApplicationsCountIfSelectIsNotAll(assigned.length);
    setPreviouslyAssignedApplicationsWithValidCount(breakdown);
  };
  useEffect(() => {
    getUnAssignedCountAndPreviouslyAssignedCountBreakDownIfNotSelectAll();
    const checkForAssignedApplicationscount = (applications) => {
      if (isSelectAll) {
        const obj = {};
        if (!isNil(applications)) {
          Object.entries(applications).forEach(([email, count]) => {
            if (count > 0) {
              obj[email] = count;
            }
          });
        }
        setPreviouslyAssignedApplicationsWithValidCount(obj);
      } else {
        getUnAssignedCountAndPreviouslyAssignedCountBreakDownIfNotSelectAll();
      }
    };
    checkForAssignedApplicationscount(previouslyAssignedApplications);
  }, [previouslyAssignedApplications]);

  return (
    <div className="applications_modal_body">
      <p className="modal_body_info_text">
        Please confirm your choice to assign these applications to
        {' '}
        <b>
          {assigneeEmail}
        </b>
      </p>
      <div className="application-count">
        <div className="application-item border-bottom application-item-heading">
          <span className="total-text">Total applications</span>
          <span className="count_text">{isSelectAll ? totalApplications : selectedTransactionsForManualAssignment.length}</span>
        </div>
        <div className="application-item application-item-heading">
          <span>Unassigned Applications</span>
          <span className="count_text">{isSelectAll ? unassignedApplications : unassignedApplicationsCountIfSelectIsNotAll}</span>
        </div>
        <div
          className="application-item collapsible"
          role="button"
          tabIndex={0}
          onClick={() => setIsCollapsed(!isCollapsed)}
          onKeyPress={(e) => {
            if (e.key === 'Enter' || e.key === ' ') {
              setIsCollapsed(!isCollapsed);
            }
          }}
        >
          <div>
            <img
              src={smallArrow}
              className={`arrow ${isCollapsed ? '' : 'open'}`}
              alt="arrow"
            />
            <span>Previously Assigned Applications</span>
          </div>
          <span className="count_text">
            {isSelectAll ? (totalApplications - unassignedApplications)
              : previouslyAssignedApplicationsCountIfSelectIsNotAll}
          </span>
        </div>
        {!isCollapsed && (
          <div className="collapse-content">
            {Object.entries(previouslyAssignedApplicationsWithValidCount).map(
              ([email, count]) => (
                <>
                  <div
                    className="application-entry"
                    key={`${email}_${count}`}
                  >
                    <span>{email}</span>
                    <span>{count}</span>
                  </div>
                </>
              ),
            )}
          </div>
        )}
      </div>
    </div>
  );
};

ModalBody.propTypes = {
  assigneeEmail: PropTypes.string.isRequired,
};

export default ModalBody;
