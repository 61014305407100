import './DefaultOnboard.scss';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { get } from 'lodash';
import * as Sentry from '@sentry/react';

import logo from '../../assests/images/logoPlain.png';
import AlertCode from '../../constants/alertCodes';
import { useFormatAnalyticsData } from '../../utils/lib';
import rudderstackEvents from '../../constants/rudderstackEventNames';
import { updateEmail, updateIsAuthenticated } from '../../reducers/user';
import { persistor } from '../../store';
import alert from '../../assests/icons/alert-v2.svg';
import {
  redirectFunction, IconLeft, startGoogleLogin, sendOtp,
} from './authHelper';
import SubmitButton from '../Common/Buttons/SubmitButton';
import PasswordInput from '../Common/Inputs/PasswordInput';
import CustomLoaderButton from '../Common/Buttons/CustomLoaderButton';
import ShakeContainer from './ShakeContainer';
import errorCodes from '../../constants/errorCode';

export default function Signup() {
  const dispatch = useDispatch();
  const [name, setName] = useState('');
  const [errorObject, setErrorObject] = useState({ error: false });
  const formatAnalyticsData = useFormatAnalyticsData();
  const [isRudderStackDataSent, setIsRudderStackDataSent] = useState(false);

  const [password, setPassword] = useState('');
  const initCondition = password !== '' && name !== '';
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const isValid = initCondition;
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingForGoogleLogin, setIsLoadingForGoogleLogin] = useState(false);
  const [isGoogleLogin, setIsGoogleLogin] = useState(false);
  const navigate = useNavigate();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const emailBase64 = query.get('email');
  const email = atob(emailBase64);
  const token = query.get('token');
  const auth = useSelector((state) => state.user.auth);

  useEffect(() => {
    formatAnalyticsData('', '', rudderstackEvents.DASHBOARD_SIGNUP_LAUNCH, 'Signup');
    setIsRudderStackDataSent(true);
  }, [!isRudderStackDataSent]);

  const handlePasswordChange = (event) => setPassword(event.target.value);

  const setOpenInputAlert = (value) => setErrorObject((prev) => ({ ...prev, error: value }));

  const setErrorMessage = (message) => setErrorObject((prev) => ({ ...prev, message }));

  const handleSubmit = async (event) => {
    setIsGoogleLogin(false);
    setErrorObject({ error: false });
    formatAnalyticsData(email, '', rudderstackEvents.DASHBOARD_SIGNUP_CTACLICK, 'Signup');
    event.preventDefault();
    if (!isValid) return;
    if (!isPasswordValid) {
      setErrorObject({ error: true, message: 'Password criteria does not match' });
      return;
    }
    setIsLoading(true);
    try {
      await axios({
        method: 'POST',
        url: `${process.env.REACT_APP_SERVER_URL}/api/v1/user/signup`,
        data: {
          email,
          password,
          name,
          token,
        },
      });
      await axios({
        method: 'POST',
        url: `${process.env.REACT_APP_SERVER_URL}/api/v1/user/login`,
        data: {
          email,
          password,
        },
      });
      dispatch(updateEmail(email));
      dispatch(updateIsAuthenticated(true));
      setIsLoading(false);
    } catch (error) {
      // Get code from self_serve - code or audit portal - errorCode
      setIsLoading(false);
      const errorCode = get(error, 'response.data.code') || get(error, 'response.data.errorCode');
      const statusCode = get(error, 'response.status');
      if (errorCode === 'mfa/not-verified' || errorCode === 'mfa/no-factors-registered') {
        window.sessionStorage.setItem('email', email);
        window.sessionStorage.setItem('password', password);
        await sendOtp(setOpenInputAlert, setErrorMessage);
        navigate('/mfa/email/otp/verify');
      } else if (statusCode === 403) {
        if (errorCode === 'auth/user-disabled') {
          Sentry.captureException(`${errorCodes.AUTH_USER_DISABLED} - ${error}`, {
            extra: {
              errorMessage: AlertCode.auth.userDisabled,
            },
          });
          setErrorObject({ error: true, message: AlertCode.auth.userDisabled });
        } else if (errorCode === 'auth/company-inactive') {
          Sentry.captureException(`${errorCodes.ONBOARDING_COMPANY_DISABLED} - ${error}`, {
            extra: {
              errorMessage: AlertCode.auth.companyInactive,
            },
          });
          setErrorObject({ error: true, message: AlertCode.auth.companyInactive });
        }
      } else {
        Sentry.captureException(`${errorCodes.ONBOARDING_SIGNUP_ERROR} - ${error}`, {
          extra: {
            errorMessage: AlertCode.SIGNUP_FAILED,
          },
        });
        setErrorObject({ error: true, message: AlertCode.SIGNUP_FAILED });
      }
      dispatch(updateIsAuthenticated(false));
    }
  };

  const googleLogin = async () => {
    setIsGoogleLogin(true);
    setIsLoadingForGoogleLogin(true);
    await startGoogleLogin(navigate, dispatch, setErrorMessage,
      setOpenInputAlert, setIsLoadingForGoogleLogin, query);
  };

  const handleEnterPress = (event) => {
    if (event.key === 'Enter') {
      handleSubmit(event);
    }
  };

  const purgePersistor = async () => {
    await persistor.flush();
    await persistor.purge();
    dispatch({ type: 'RESET' });
  };

  const handleNameChange = (event) => setName(event.target.value);

  useEffect(() => {
    purgePersistor();
  }, []);

  useEffect(() => {
    if (query.get('status') === 'error') {
      setOpenInputAlert(true);
      const errCode = query.get('code') || 'unhandled-error';
      const errMessage = query.get('message') || 'Something went wrong';
      if (errCode === 'user/not-found') setErrorMessage('Account does not exist. Choose another account or contact admin');
      else setErrorMessage(errMessage);
      dispatch(updateIsAuthenticated(false));
      Sentry.captureException(`${errCode} - ${errMessage}`, {
        extra: {
          errorMessage: errMessage,
        },
      });
      navigate(window.location.pathname);
    }
  }, [query]);

  useEffect(() => {
    if (auth && !isGoogleLogin && query.get('status') !== 'error') redirectFunction(dispatch, navigate, auth, query);
  }, [auth]);

  return (
    <div className="onboard-page">
      <img src={logo} alt="logo" className="hv-logo" />
      <ShakeContainer isError={errorObject?.error}>
        <h2 className="sign-in-font">Create Account</h2>
        <div className="column-display">
          <CustomLoaderButton
            isLoading={isLoadingForGoogleLogin}
            onClick={googleLogin}
            Icon={IconLeft}
            buttonText="Continue with Google"
          />
        </div>
        <div className="row-display margin-20">
          <hr className="horizontal-line" />
          <span className="or-text">or</span>
          <hr className="horizontal-line" />
        </div>
        <div className="column-display">
          <input
            data-hj-suppress
            id="input-box"
            type="text"
            placeholder="Email Address"
            value={email}
            disabled
          />
          <input
            data-hj-suppress
            id="input-box"
            type="text"
            placeholder="Full Name"
            onChange={handleNameChange}
          />
          <PasswordInput
            modalClassName="password-modal top-440px"
            id="input-box"
            handlePasswordChange={handlePasswordChange}
            placeholder="Create a password"
            setPasswordValidity={setIsPasswordValid}
            shouldShownValidationModal
            onKeyDown={handleEnterPress}
          />
        </div>
        <SubmitButton
          isValid={isValid}
          isLoading={isLoading}
          handleSubmit={handleSubmit}
          buttonText="Create Account"
        />
        {errorObject?.error && (
          <div className="invalid-field display-center">
            <img className="alert-icon" src={alert} alt="!" />
            <span>{errorObject?.message}</span>
          </div>
        )}
      </ShakeContainer>
    </div>
  );
}
