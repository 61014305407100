import React from 'react';
import PropTypes from 'prop-types';
import { get, lowerCase } from 'lodash';
import showApplicationStatus from '../../../../constants/showApplicationStatus';
import { APPLICATION_DETAILS_STATUS_TOOLTIP_MAP } from '../../../../constants/applicationStatus';
import ConditionalToolTip from '../../../Common/ConditionalToolTip';

function Header({ status }) {
  const tooltip = get(APPLICATION_DETAILS_STATUS_TOOLTIP_MAP, status, '');
  return (
    <div id="right_panel__review_card__status">
      <p>Status:</p>
      <ConditionalToolTip title={tooltip} placement="left">
        <span className={status}>
          {showApplicationStatus(status)?.status
          || lowerCase(status)}
        </span>
      </ConditionalToolTip>
    </div>
  );
}

Header.propTypes = {
  status: PropTypes.string.isRequired,
};

export default Header;
