import React from 'react';
import PropTypes from 'prop-types';

function FetchDataError({ onRetry, text }) {
  return (
    <div className="fetch-data-error">
      <p className="fetch-data-error__message">{text}</p>
      {onRetry && (
        <button type="button" className="fetch-data-error__retry-button" onClick={onRetry}>
          Retry
        </button>
      )}
    </div>
  );
}

FetchDataError.propTypes = {
  onRetry: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
};

export default FetchDataError;
