import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { get } from 'lodash';
import userSvg from '../../../../assests/icons/user.svg';
import ManualAssignmentPopup from '../popups/ManualAssignmentPopup';
import useGetUserPermissions from '../../../../Permissions/hooks';
import { constants } from '../../../../config';
import checkForManualAssignmentPermissions from '../../utils';

function ManualAssigneeCard({
  onFilterAdd, selectedAssignee, currentAssignee,
}) {
  const getUserPermissions = useGetUserPermissions();
  const { allowedPermissions } = getUserPermissions;

  const applicationManualReviewAssignment = useSelector((state) => get(state, 'config.applicationManualReviewAssignment', 'none'));
  const transactionData = useSelector((state) => get(state, 'appDetails.transactionData', {}));
  const user = useSelector((state) => get(state, 'user.email', ''));
  const { status } = transactionData;

  const checkPermissions = () => {
    const canAssignManually = checkForManualAssignmentPermissions(
      allowedPermissions,
      constants.PERMISSIONS_TO_ENABLE_MANUAL_ASSIGMENT,
      [{ key: applicationManualReviewAssignment, value: 'none' }],
    );

    const canAssignToSelf = checkForManualAssignmentPermissions(
      allowedPermissions,
      constants.PERMISSIONS_TO_ENABLE_MANUAL_ASSIGNMENT_TO_THEMSELVES,
      [{ key: applicationManualReviewAssignment, value: 'none' }],
    ) && user === currentAssignee;

    const canAssignToSelfWhenNoAssignee = checkForManualAssignmentPermissions(
      allowedPermissions,
      constants.PERMISSIONS_TO_ENABLE_MANUAL_ASSIGNMENT_TO_THEMSELVES,
      [{ key: applicationManualReviewAssignment, value: 'none' }],
    ) && !currentAssignee;

    return (canAssignManually || canAssignToSelf || canAssignToSelfWhenNoAssignee) && status === 'needs_review';
  };

  return (
    <div id="right_panel__review_card__assignee_container">
      <div className="assignee_container">
        <div className="assignee_header">
          <p>Assignee</p>
        </div>
        <div className="assignee_body">
          <img src={userSvg} alt="user" />
          <ManualAssignmentPopup
            onFilterAdd={onFilterAdd}
            selectedFilterList={selectedAssignee}
            position="bottom-left"
            currentAssignee={currentAssignee || null}
            hasPermissions={checkPermissions()}
          />
        </div>
      </div>
    </div>
  );
}

ManualAssigneeCard.propTypes = {
  onFilterAdd: PropTypes.func.isRequired,
  selectedAssignee: PropTypes.string,
  currentAssignee: PropTypes.string,
};

ManualAssigneeCard.defaultProps = {
  selectedAssignee: '',
  currentAssignee: 'UnAssigned',
};

export default ManualAssigneeCard;
