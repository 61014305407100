/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { startOfDay, endOfDay, addDays } from 'date-fns';
import { get, set } from 'lodash';
import { formatDateForAnalytics } from '../utils/dateFormats';

const defaultColumns = [
  {
    id: 'transactionId',
    value: 'transactionId',
    label: 'Transaction ID',
  },
  {
    id: 'createdAt',
    value: 'createdAt',
    label: 'Created',
  },
  {
    id: 'status',
    value: 'status',
    label: 'Status',
  },
  {
    id: 'workflowId',
    value: 'workflowId',
    label: 'Workflow Name',
  },
];

const defaultDashboardDataTableColumns = [];

const applicationCreatedAtInitState = {
  filterData: {
    selectedDateRange: {
      startTime: startOfDay(addDays(new Date(), -14)),
      endTime: endOfDay(new Date()),
    },
  },
  value: {
    // format: 2022-04-11T18:30:00.000Z
    startTime: startOfDay(addDays(new Date(), -14)),
    endTime: endOfDay(new Date()),
  },
};

const statusInitState = {
  filterData: {},
  value: {
    contains: true,
    values: [],
  },
};

const metaDataInitState = {
  reviewerEmail: {
    filterData: {},
    value: {
      contains: true,
      values: [],
    },
  },
  workflowId: {
    filterData: {},
    value: {
      contains: true,
      values: [],
    },
  },
  device: {
    filterData: {},
    value: {
      contains: true,
      values: [],
    },
  },
  browser: {
    filterData: {},
    value: {
      contains: true,
      values: [],
    },
  },
  platformVersion: {
    filterData: {},
    value: {
      contains: true,
      values: [],
    },
  },
  platform: {
    filterData: {},
    value: {
      contains: true,
      values: [],
    },
  },
  sdkVersion: {
    filterData: {},
    value: {
      contains: true,
      values: [],
    },
  },
  appVersion: {
    filterData: {},
    value: {
      contains: true,
      values: [],
    },
  },
  ipAddress: {
    filterData: {},
    value: {
      contains: true,
      values: [],
    },
  },
  country: {
    filterData: {},
    value: {
      contains: true,
      values: [],
    },
  },
  dob: {
    filterData: {},
    value: {
      startTime: '',
      endTime: '',
    },
  },
  os: {
    filterData: {},
    value: {
      contains: true,
      values: [],
    },
  },
};

const sortByInitState = {
  column: 'createdAt',
  direction: 'desc',
};

const initSelectHeadStateForApplicationsTable = {
  headState: {
    all: false,
    pageAll: false,
    none: true,
  },
  pageState: {
    pageNo: 0,
    pageSize: 0,
  },
};

const initAssignedTransactionCountData = {
  total: 0,
  unAssigned: 0,
};

export const dataSlice = createSlice({
  name: 'data',
  initialState: {
    value: [],
    totalElements: 0,
    status: statusInitState,
    application: applicationCreatedAtInitState,
    analytics: {
      // Formating time according to api request type
      // format: 2022/04/12
      startTime: formatDateForAnalytics(new Date()),
      endTime: formatDateForAnalytics(new Date()),
    },
    page: 0,
    sortBy: sortByInitState,
    statusOnTable: '',
    reason: 'Reason',
    metaData: metaDataInitState,
    selectedColumns:
      JSON.parse(localStorage.getItem('selectedColumn')) || defaultColumns,
    dashboardDataTableColumns: defaultDashboardDataTableColumns,
    comments: [],
    showAmlDetails: false,
    showAmlDetailsData: {},
    transactionMetadataFilters: {},
    reviewersList: [],
    previouslyAssignedReviewersList: {},
    selectHeadStateForApplicationsTable:
      initSelectHeadStateForApplicationsTable,
    selectedTransactionsForManualAssignment: {},
    assignedTransactionCountData: initAssignedTransactionCountData,
  },
  reducers: {
    updateData: (state, action) => {
      state.value = action.payload;
    },
    updateShowAmlDetails: (state, action) => {
      state.showAmlDetails = action.payload;
    },
    updateShowAmlDetailsData: (state, action) => {
      state.showAmlDetailsData = action.payload;
    },
    updateTotalElements: (state, action) => {
      state.totalElements = action.payload;
    },
    updateSortBy: (state, action) => {
      state.sortBy = action.payload;
    },
    updateStatus: (state, action) => {
      const { data, filterData } = action.payload;
      const { value } = data;
      state.status.value = value || statusInitState.value;
      state.status.filterData = filterData || statusInitState.filterData;
    },
    updateTransactionMetadataFilters: (state, action) => {
      const { data, filterData } = action.payload;
      const { key, value } = data;
      set(state, ['transactionMetadataFilters', key], { value, filterData });
    },
    updateTimePeriod: (state, action) => {
      const {
        source, startDate, endDate, value, filterData,
      } = action.payload;
      if (source === 'analytics') {
        // Formating time according to api request type
        state[source].startTime = `${formatDateForAnalytics(startDate)}`;
        state[source].endTime = `${formatDateForAnalytics(endDate)}`;
      } else {
        state[source].value = value || applicationCreatedAtInitState.value;
        state[source].filterData = filterData || applicationCreatedAtInitState.filterData;
      }
    },
    updatePage: (state, action) => {
      state.page = action.payload;
    },
    updateMetaDataFilters: (state, action) => {
      const { data, filterData } = action.payload;
      const { key, value } = data;
      set(state, ['metaData', key], {
        value: value || get(metaDataInitState, [key, 'value']),
        filterData: filterData || get(metaDataInitState, [key, 'filterValue']),
      });
    },
    updateColumns: (state, action) => {
      localStorage.setItem(
        'selectedColumn',
        JSON.stringify(action.payload || defaultColumns),
      );
      state.selectedColumns = action.payload || defaultColumns;
    },
    updateDashboardDataTableColumns: (state, action) => {
      set(state, 'dashboardDataTableColumns', action?.payload || defaultDashboardDataTableColumns);
    },
    updateComments: (state, action) => {
      state.comments = action.payload;
    },
    updateReviewersList: (state, action) => {
      state.reviewersList = action.payload.reviewersList;
    },
    updateSelectHeadStateForApplicationsTable: (state, action) => {
      state.selectHeadStateForApplicationsTable = action.payload;
    },
    updateSelectedTransactions: (state, action) => {
      const transactionId = action.payload;

      if (Array.isArray(transactionId)) {
        state.selectedTransactionsForManualAssignment = transactionId.reduce(
          (acc, id) => {
            acc[id] = { value: id };
            return acc;
          },
          {},
        );
        return;
      }

      state.selectedTransactionsForManualAssignment = {
        ...state.selectedTransactionsForManualAssignment,
        [transactionId]: { value: transactionId },
      };
    },
    removeSelectedTransaction: (state, action) => {
      const transactionId = action.payload;

      if (!state.selectedTransactionsForManualAssignment) {
        state.selectedTransactionsForManualAssignment = {};
      }

      delete state.selectedTransactionsForManualAssignment[transactionId];
    },
    clearAllSelectedTransactions: (state) => {
      state.selectedTransactionsForManualAssignment = {};
    },
    updatePreviousAssignedList: (state, action) => {
      state.previouslyAssignedReviewersList = action.payload;
    },
    updateAssignedTransactionCountData: (state, action) => {
      state.assignedTransactionCountData = action.payload || initAssignedTransactionCountData;
    },
  },
});

// Action creators are generated for each case reducer function``
export const {
  updateData,
  updateTotalElements,
  updateStatus,
  updatePage,
  resetDataFilters,
  updateSortBy,
  updateProduct,
  updateProductList,
  updateCode,
  updateTimePeriod,
  updateMetaDataFilters,
  updateColumns,
  updateComments,
  updateShowAmlDetails,
  updateShowAmlDetailsData,
  updateTransactionMetadataFilters,
  updateReviewersList,
  updateDashboardDataTableColumns,
  updateSelectHeadStateForApplicationsTable,
  updateSelectedTransactions,
  removeSelectedTransaction,
  clearAllSelectedTransactions,
  updatePreviousAssignedList,
  updateAssignedTransactionCountData,
} = dataSlice.actions;
export default dataSlice.reducer;
