import { hasRequiredPermissions, validateConditions } from '../../utils/helpers';

const checkForManualAssignmentPermissions = (
  availablePermissions,
  permissionsArrayToCheck,
  validationConditions,
) => hasRequiredPermissions(
  availablePermissions, permissionsArrayToCheck,
) && validateConditions(validationConditions);

export default checkForManualAssignmentPermissions;
