import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import CheckBox from '../../../../Common/Inputs/CommonCheckbox';
import warning from '../../../../../assests/icons/warning.svg';
import './ReviewReasonModal.scss';
import REVIEW_DECISION_REASON_TYPES from '../../../../../constants/record';

function ReasonSelection({
  options,
  selectedReviewDecisions,
  onDecisionSelect,
  isMultiSelect,
  allowCustomReason,
  onCustomInputChange,
  showAlert,
  onAlertClose,
  alertMessage,
}) {
  const inputRef = useRef(null);

  useEffect(() => {
    if (selectedReviewDecisions?.customOption?.selected && inputRef?.current) {
      inputRef?.current?.focus();
    }
  }, [selectedReviewDecisions?.customOption?.selected]);

  const handleSelectionChange = (value) => {
    onDecisionSelect(value);
  };

  return (
    <div className="reason-selection">
      {options.map((option) => (
        <div
          key={option.id}
          className={`reason-option ${
            selectedReviewDecisions?.selectedReasonsList?.includes(option?.text) ? 'selected' : ''
          }`}
          onClick={() => handleSelectionChange(option?.text)}
          role="button"
          tabIndex={0}
          onKeyDown={(e) => e.key === 'Enter' && handleSelectionChange(option?.text)}
        >
          {isMultiSelect ? (
            <CheckBox
              checked={selectedReviewDecisions?.selectedReasonsList?.includes(option?.text)}
              disabled={false}
              onChange={() => handleSelectionChange(option?.text)}
            />
          ) : (
            <div className="singleSelect-input-container">
              <input
                type="radio"
                id={option.id}
                name="reason"
                value={option.text}
                checked={selectedReviewDecisions?.selectedReasonsList?.includes(option.text)}
                onChange={() => handleSelectionChange(option?.text)}
              />
            </div>
          )}
          <label htmlFor={option?.id}>{option?.text}</label>
        </div>
      ))}
      {allowCustomReason && (
      <div className="custom-input-container">
        <div
          className={`reason-option ${
            selectedReviewDecisions?.customOption?.selected ? 'selected' : ''
          }`}
          onClick={() => handleSelectionChange(REVIEW_DECISION_REASON_TYPES.CUSTOM)}
          role="button"
          tabIndex={0}
          onKeyDown={(e) => e.key === 'Enter' && handleSelectionChange(REVIEW_DECISION_REASON_TYPES.CUSTOM)}
        >
          {isMultiSelect ? (
            <CheckBox
              checked={selectedReviewDecisions?.customOption?.selected}
              disabled={false}
              onChange={() => handleSelectionChange(REVIEW_DECISION_REASON_TYPES.CUSTOM)}
            />
          ) : (
            <div className="singleSelect-input-container">
              <input
                type="radio"
                id="customOption"
                name="reason"
                value={selectedReviewDecisions?.customOption?.value}
                checked={selectedReviewDecisions?.customOption?.selected}
                onChange={() => handleSelectionChange(REVIEW_DECISION_REASON_TYPES.CUSTOM)}
              />
            </div>
          )}
          <label htmlFor="customOption">{selectedReviewDecisions?.customOption?.selected ? 'Other:' : 'Other'}</label>
        </div>
        {selectedReviewDecisions?.customOption?.selected && (
        <div className="singleSelect-custom-input-container">
          <input
            ref={inputRef}
            id="customOption_input"
            name="customReason"
            value={selectedReviewDecisions?.customOption?.value || ''}
            onChange={(e) => onCustomInputChange(e.target.value)}
          />
        </div>
        )}
      </div>
      )}
      {showAlert && (
      <div className="alert-container">
        <div className="custom-validation-alert">
          <img src={warning} alt="warning" />
          <p>{alertMessage}</p>
          <button type="button" onClick={onAlertClose}>Dismiss</button>
        </div>
      </div>
      )}
    </div>
  );
}

ReasonSelection.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
    }),
  ).isRequired,
  selectedReviewDecisions: PropTypes.shape({
    selectedReasonsList: PropTypes.arrayOf(PropTypes.string).isRequired,
    customOption: PropTypes.shape({
      selected: PropTypes.bool.isRequired,
      value: PropTypes.string,
    }),
  }).isRequired,
  onDecisionSelect: PropTypes.func.isRequired,
  onCustomInputChange: PropTypes.func.isRequired,
  isMultiSelect: PropTypes.bool.isRequired,
  allowCustomReason: PropTypes.bool.isRequired,
  showAlert: PropTypes.bool.isRequired,
  onAlertClose: PropTypes.func.isRequired,
  alertMessage: PropTypes.string.isRequired,
};

export default ReasonSelection;
