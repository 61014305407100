import * as Sentry from '@sentry/react';
import axios from 'axios';
import { get, keys } from 'lodash';

const axiosRequest = axios.create({
  baseURL: `${process.env.REACT_APP_SERVER_URL}/api/v1/iam`,
  withCredentials: true,
});

const getReviewerListApi = async ({
  appId, startTime, endTime, ...filters
}) => {
  try {
    const res = await axiosRequest.post('/reviewers', {
      startTime, endTime, ...filters,
    }, {
      headers: { appId },
    });
    const reviewers = get(res, 'data.result.roles');
    const previouslyAssignedReviewersList = get(res, 'data.result.email');

    const reviewersList = keys(reviewers).reduce((acc, role) => {
      acc.push({ role, emails: reviewers[role].map((email) => ({ id: email })) });
      return acc;
    }, []);
    return { reviewersList, previouslyAssignedReviewersList };
  } catch (error) {
    Sentry.captureException(error);
    return { reviewersList: [], previouslyAssignedReviewersList: {} };
  }
};

export default getReviewerListApi;
