import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { PermissionWrapper } from 'storybook-ui-components';
import '../Comment/Comment.scss';
import { useSelector } from 'react-redux';
import { Spinner } from 'clm-components';
import { isEmpty, get } from 'lodash';
import * as Sentry from '@sentry/react';
import { ReactComponent as DeclineIcon } from '../../../../assests/icons/declineIcon.svg';
import { ReactComponent as ApproveIcon } from '../../../../assests/icons/approveIcon.svg';
import { buildRequestsForReview, makeRequestsForReview } from './utils';
import useShowErrorAlert from '../../../../utils/lib';
import useGetUserPermissions from '../../../../Permissions/hooks';
import getPermission from '../../../../Permissions/mapping';
import AlertCode from '../../../../constants/alertCodes';
import errorCode from '../../../../constants/errorCode';
import { constants } from '../../../../config';
import checkForManualAssignmentPermissions from '../../../ManualAssignment/utils';
import ReviewDecisionModal from './ReviewReasonsModal/ReviewReasonModal';
import ReAssignmentConfirmationModal from './ReAssignmentConfirmationModal/ReAssignmentConfirmationModal';
import REVIEW_DECISION_REASON_TYPES from '../../../../constants/record';

function ReviewButtons({
  updateApplicationStatus,
  applicationStatus,
  reviewerEmail,
  onChangeAssingeeForReAssignment,
  onReAssignment,
}) {
  const getUserPermissions = useGetUserPermissions();
  const { allowedPermissions } = getUserPermissions;
  const reviewTagsConfig = useSelector((state) => state.appDetails.reviewTags);
  const hitsReviewTagConfig = useSelector((state) => state.appDetails.hitsReviewTags);
  const currentCredential = useSelector((state) => state.appDetails.currentCredential);
  const applicationAssignmentType = useSelector((state) => state
    .config.applicationManualReviewAssignment);
  const appState = useSelector((state) => state.appDetails);
  const userEmail = useSelector((state) => state.user.email);

  const [isLoading, setIsLoading] = useState(false);
  const [statusForUpdatingApplication, setStatusForUpdatingApplication] = useState(null);

  const [showReAssignmentConfirmationModal, setShowReAssignmentConfirmationModal] = useState(false);
  const [showReviewDescisionModal, setShowReviewDescisionModal] = useState(false);
  const reviewDescisionConfig = useSelector((state) => get(state, 'appDetails.reviewReasonsConfig', {}));

  const initSelectedReviewDecisionsState = {
    customOption: {
      selected: false,
      value: null,
    },
    selectedReasonsList: [],
  };

  const [selectedReviewDecisions, setSelectedReviewDecisions] = useState(
    initSelectedReviewDecisionsState,
  );

  const showErrorAlert = useShowErrorAlert();

  if (applicationStatus !== 'needs_review') {
    return '';
  }

  const checkUserHasPermissionsToReassignApplication = () => checkForManualAssignmentPermissions(
    allowedPermissions,
    constants.PERMISSIONS_TO_ENABLE_MANUAL_ASSIGMENT_REASSIGNMENT,
    [{
      key: applicationAssignmentType,
      value: 'none',
    }],
  );

  // eslint-disable-next-line max-len
  const checkUserHasPermissionsToApproveOrDeclineOfOthers = () => checkForManualAssignmentPermissions(
    allowedPermissions,
    constants.PERMISSIONS_TO_ENABLE_MANUAL_ASSIGNMENT_TO_ALL,
    [{
      key: applicationAssignmentType,
      value: 'none',
    }],
  );

  const showReAssignmentModalForConfirmation = (status) => {
    setShowReAssignmentConfirmationModal(true);
    setStatusForUpdatingApplication(status);
    onChangeAssingeeForReAssignment(userEmail);
  };

  const onConfirmationModalClose = () => {
    setShowReAssignmentConfirmationModal(false);
    setStatusForUpdatingApplication(null);
    onChangeAssingeeForReAssignment(null);
  };

  const handleReAssignAndApproveApplication = async (status, decisions) => {
    try {
      setIsLoading(true);
      setShowReviewDescisionModal(false);
      onConfirmationModalClose();
      await onReAssignment(status, decisions);
      setIsLoading(false);
    } catch (error) {
      onConfirmationModalClose();
      setIsLoading(false);
      showErrorAlert({ error, message: AlertCode.UPDATE_APPLICATION_STATUS });
      Sentry.captureException(`${errorCode.APPLICATION_STATUS_UPDATE_ERROR} - ${error}`, {
        extra: {
          errorMessage: AlertCode.UPDATE_APPLICATION_STATUS,
        },
      });
    }
  };

  const checkForReviewDescisionModalAndReAssignApplication = async (status) => {
    if (!isEmpty(reviewDescisionConfig)) {
      onConfirmationModalClose();
      setStatusForUpdatingApplication(status);
      setShowReviewDescisionModal(true);
      return;
    }
    handleReAssignAndApproveApplication(status, []);
  };

  const onConfirmReviewDescisionModalClose = () => {
    onConfirmationModalClose();
    setShowReviewDescisionModal(false);
    setSelectedReviewDecisions(initSelectedReviewDecisionsState);
  };

  const handleConfirmReviewDescisionModal = async (decisions) => {
    setIsLoading(true);
    setShowReviewDescisionModal(false);
    setSelectedReviewDecisions(initSelectedReviewDecisionsState);
    if ((!reviewerEmail || (reviewerEmail !== userEmail))
      && checkUserHasPermissionsToReassignApplication()) {
      await onReAssignment(statusForUpdatingApplication, decisions);
      return;
    }
    setIsLoading(true);
    await updateApplicationStatus(statusForUpdatingApplication, decisions);
    setIsLoading(false);
  };

  const onButtonClick = async (status) => {
    setIsLoading(true);
    try {
      if ((!reviewerEmail || (reviewerEmail !== userEmail))
        && checkUserHasPermissionsToReassignApplication()) {
        showReAssignmentModalForConfirmation(status);
        return;
      }
      if (!isEmpty(reviewDescisionConfig)) {
        setStatusForUpdatingApplication(status);
        setShowReviewDescisionModal(true);
        return;
      }
      setShowReviewDescisionModal(false);
      if (!isEmpty(reviewTagsConfig) || !isEmpty(hitsReviewTagConfig)) {
        const { requests, error } = buildRequestsForReview(
          reviewTagsConfig, hitsReviewTagConfig, appState,
        );
        if (error) {
          Sentry.captureException(error);
          showErrorAlert({ message: error.message });
          return;
        }
        const {
          failureResponseList,
        } = await makeRequestsForReview(requests, { ...currentCredential });

        if (failureResponseList.length) {
          const errorMessage = failureResponseList.find(({ message }) => !!message)?.message;
          showErrorAlert({ message: errorMessage });
          return;
        }
      }
      await updateApplicationStatus(status, []);
    } catch (error) {
      showErrorAlert({ error, message: AlertCode.UPDATE_APPLICATION_STATUS });
      Sentry.captureException(`${errorCode.APPLICATION_STATUS_UPDATE_ERROR} - ${error}`, {
        extra: {
          errorMessage: AlertCode.UPDATE_APPLICATION_STATUS,
        },
      });
    } finally {
      setIsLoading(false);
    }
  };

  const onReviewDecisionSelect = (decision) => {
    const isMultiSelect = get(
      reviewDescisionConfig,
      `${statusForUpdatingApplication}.reasonType`,
      REVIEW_DECISION_REASON_TYPES.SINGLE,
    ) !== REVIEW_DECISION_REASON_TYPES.SINGLE;

    const { selectedReasonsList, customOption } = selectedReviewDecisions;

    const updateCustomOption = (selected) => ({
      ...customOption,
      value: null,
      selected,
    });

    if (decision === REVIEW_DECISION_REASON_TYPES.CUSTOM) {
      setSelectedReviewDecisions({
        selectedReasonsList: isMultiSelect ? selectedReasonsList : [],
        customOption: updateCustomOption(!customOption.selected),
      });
      return;
    }

    if (isMultiSelect) {
      const updatedReasonsList = selectedReasonsList.includes(decision)
        ? selectedReasonsList.filter((d) => d !== decision)
        : [...selectedReasonsList, decision];

      setSelectedReviewDecisions({
        ...selectedReviewDecisions,
        selectedReasonsList: updatedReasonsList,
      });
    } else {
      setSelectedReviewDecisions({
        customOption: updateCustomOption(false),
        selectedReasonsList: [decision],
      });
    }
  };

  const handleCustomInputChange = (customValue) => {
    const { selectedReasonsList, customOption } = selectedReviewDecisions;
    customOption.value = customValue;
    setSelectedReviewDecisions({
      selectedReasonsList,
      customOption,
    });
  };

  const getButtonID = (type) => {
    if (applicationAssignmentType === 'none') return `review_buttons_button_${type}`;
    if (reviewerEmail && reviewerEmail !== userEmail && !checkUserHasPermissionsToApproveOrDeclineOfOthers()) return 'review_buttons_button_disable';
    return `review_buttons_button_${type}`;
  };

  if (isLoading) {
    return (
      <div id="review_buttons_container" className="loading">
        <Spinner />
      </div>
    );
  }
  return (
    <>
      <div id="review_buttons_container">
        <button
          type="button"
          id={getButtonID('decline')}
          onClick={() => onButtonClick('manually_declined')}
        >
          <DeclineIcon />
          <p>Decline</p>
          <span className="left-disable-button">
            Only Assignee or Admins of the platform can review this application
          </span>
        </button>
        <button
          type="button"
          id={getButtonID('approve')}
          onClick={() => onButtonClick('manually_approved')}
        >
          <ApproveIcon />
          <p>Approve</p>
          <span className="right-disable-button">
            Only Assignee or Admins of the platform can review this application
          </span>
        </button>
      </div>
      <ReviewDecisionModal
        isOpen={showReviewDescisionModal}
        onClose={onConfirmReviewDescisionModalClose}
        onSubmit={handleConfirmReviewDescisionModal}
        status={statusForUpdatingApplication}
        optionsConfig={reviewDescisionConfig}
        selectedReviewDecisions={selectedReviewDecisions}
        onDecisionSelect={onReviewDecisionSelect}
        onCustomInputChange={handleCustomInputChange}
      />
      <ReAssignmentConfirmationModal
        isOpen={showReAssignmentConfirmationModal}
        onClose={onConfirmationModalClose}
        onSubmit={
          () => checkForReviewDescisionModalAndReAssignApplication(statusForUpdatingApplication)
        }
        status={statusForUpdatingApplication}
        reviewerEmail={reviewerEmail}
        currentUserEmail={userEmail}
      />
    </>
  );
}

ReviewButtons.propTypes = {
  updateApplicationStatus: PropTypes.func.isRequired,
  applicationStatus: PropTypes.string.isRequired,
  reviewerEmail: PropTypes.string.isRequired,
  onChangeAssingeeForReAssignment: PropTypes.func.isRequired,
  onReAssignment: PropTypes.func.isRequired,
};

export default PermissionWrapper(ReviewButtons, useGetUserPermissions, getPermission('auditButtons'));
