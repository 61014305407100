import React from 'react';
import { useSelector } from 'react-redux';

import { Spinner } from 'clm-components';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { PlatformPricingTable, ModulePricingTable } from 'storybook-ui-components';
import Header from './Header';

function Body({
  isLoading, modulePricingInfo, platformPricingInfo, currency, usageConfig,
}) {
  const currencySymbolMap = useSelector((state) => get(state, 's3Config.commonConfig.configData.currency', []));

  if (isLoading) {
    return (
      <div id="loading-spinner">
        <Spinner />
      </div>
    );
  }
  return (
    <div id="client_pricing_info__body_container">
      <Header headerText="Module Pricing" id="client_pricing_info__module_header_display" />
      <ModulePricingTable
        pricingInfo={modulePricingInfo}
        currency={currency}
        usageConfig={usageConfig}
        currencySymbolMap={currencySymbolMap}
      />
      <Header headerText="Platform Pricing" />
      <PlatformPricingTable
        platformPricingData={platformPricingInfo}
        isLoading={isLoading}
        currency={currency}
        currencySymbolMap={currencySymbolMap}
      />
    </div>
  );
}

Body.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  platformPricingInfo: PropTypes.array.isRequired,
  modulePricingInfo: PropTypes.array.isRequired,
  currency: PropTypes.string.isRequired,
  usageConfig: PropTypes.object.isRequired,
};

export default Body;
