import React, { useEffect } from 'react';
import { isNull } from 'lodash';
import PropTypes from 'prop-types';

function SelectAssigneeButton({
  handleClick, isActive, selectedFilter, currentAssignee, hasPermissions,
}) {
  useEffect(() => {
    const element = document.getElementById('record__right_panel');
    if (element) {
      element.style.overflow = isActive ? 'visible' : 'auto';
    }
  }, [isActive]);

  return (
    <div className={hasPermissions ? 'current_assignee_container_with_permissions' : 'current_assignee_container'} onClick={hasPermissions && handleClick} aria-hidden>
      <p>{isNull(currentAssignee) ? 'Unassigned' : currentAssignee}</p>
      {hasPermissions
      && (
      <button
        type="button"
        id="change_assignee_button"
        className={isActive ? 'active change_assignee_button' : 'change_assignee_button'}
        onClick={handleClick}
      >
        <span>{isActive ? (selectedFilter || 'Reassign') : 'Reassign'}</span>
      </button>
      )}
    </div>

  );
}

SelectAssigneeButton.defaultProps = {
  currentAssignee: null,
};

SelectAssigneeButton.propTypes = {
  handleClick: PropTypes.func.isRequired,
  isActive: PropTypes.bool.isRequired,
  selectedFilter: PropTypes.string.isRequired,
  currentAssignee: PropTypes.string,
  hasPermissions: PropTypes.bool.isRequired,
};

export default SelectAssigneeButton;
