const APPLICATION_STATUS_MAP = {
  ERROR: 'error',
  KYC_IN_PROGRESS: 'kyc_in_progress',
};

const APPLICATION_DETAILS_STATUS_TOOLTIP_MAP = {
  [APPLICATION_STATUS_MAP.KYC_IN_PROGRESS]: "One or more of the modules' data is missing. Please reach out to HyperVerge support",
};

module.exports = { APPLICATION_STATUS_MAP, APPLICATION_DETAILS_STATUS_TOOLTIP_MAP };
