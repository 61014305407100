import axios from 'axios';

const axiosRequest = axios.create({
  baseURL: process.env.REACT_APP_SERVER_URL,
  withCredentials: true,
});

const getConfigApi = async (appId, configKey) => {
  axiosRequest.defaults.headers.appId = appId;
  const res = await axiosRequest.get(`/api/v1/config/${configKey}`);
  return res.data.result;
};

export default getConfigApi;
