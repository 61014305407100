/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import _, { get } from 'lodash';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import MultiSelectCard from '../../Common/Cards/MultiSelectCard';

function DynamicSelectWrapper(props) {
  const {
    title, placeholder, labelPath, idPath, stateKeyPath,
    getOptionListMethod,
  } = props;
  const stateValue = useSelector((state) => get(state, stateKeyPath));
  const optionList = getOptionListMethod(stateValue);

  if (optionList?.length) {
    const options = optionList.map((option) => {
      const value = get(option, idPath);
      return {
        id: value,
        value: value === 'Unassigned' ? null : value,
        label: get(option, labelPath) || _.startCase(value),
      };
    });

    return (
      <MultiSelectCard
        {...props}
        options={options}
        title={title}
        placeholder={placeholder}
      />
    );
  }
}

DynamicSelectWrapper.defaultProps = {
  getOptionListMethod: (stateValue) => stateValue,
};

DynamicSelectWrapper.propTypes = {
  stateKeyPath: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  labelPath: PropTypes.string.isRequired,
  idPath: PropTypes.string.isRequired,
  getOptionListMethod: PropTypes.func,
};

export default DynamicSelectWrapper;
