import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { get } from 'lodash';
import SelectAssigneeFilterCard from '../../common/filtercards/SelectAssigneFilterCard';
import '../../../Common/Filter/Filter.scss';
import CustomPopupContainer from '../../../Common/Popup/CustomPopupContainer';
import SelectAssigneeButton from '../buttons/SelectAssigneeButton';

function ManualAssignmentPopup({
  onFilterAdd, selectedFilterList, position, currentAssignee, hasPermissions,
}) {
  const [currentSelectedFilter, setCurrentSelectedFilter] = useState('');
  const availableReviewersList = useSelector((state) => get(state, 'data.reviewersList', []));
  return (
    <CustomPopupContainer
      popupTrigger={(
        <SelectAssigneeButton
          selectedFilter={currentSelectedFilter}
          currentAssignee={currentAssignee}
          hasPermissions={hasPermissions}
        />
)}
      popupComponent={(
        <SelectAssigneeFilterCard
          onFilterAdd={onFilterAdd}
          availableFilterList={availableReviewersList}
          selectedFilterList={selectedFilterList}
          setCurrentSelectedFilter={setCurrentSelectedFilter}
          cardSize="small-container"
          handleClose={() => onFilterAdd(null)}
          currentAssignee={currentAssignee}
        />
      )}
      popupPosition={position}
      customContainerClassName="assignee_popup_container"
    />
  );
}

ManualAssignmentPopup.defaultProps = {
  currentAssignee: null,
};

ManualAssignmentPopup.propTypes = {
  onFilterAdd: PropTypes.func.isRequired,
  selectedFilterList: PropTypes.array.isRequired,
  position: PropTypes.string.isRequired,
  currentAssignee: PropTypes.string,
  hasPermissions: PropTypes.bool.isRequired,
};

export default ManualAssignmentPopup;
