import PropTypes from 'prop-types';
import React from 'react';

const ModalBody = ({
  assigneeEmail,
}) => (
  <div className="modal-body-record">
    <p>
      Please confirm your choice to assign this application to
      {' '}
      <b>
        {assigneeEmail}
      </b>
    </p>
  </div>
);

ModalBody.propTypes = {
  assigneeEmail: PropTypes.string.isRequired,
};

export default ModalBody;
