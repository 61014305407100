import { TimelineItem } from '@mui/lab';
import React from 'react';

function EndTimelineViewItem() {
  return (
    <TimelineItem className="application_details__timeline_view__end__container">
      <div className="application_details__timeline_view__end__content">
        <div className="application_details__timeline_view__end__line__container">
          <div className="application_details__timeline_view__end__line" />
          <div className="application_details__timeline_view__end__diamond" />
          <div className="application_details__timeline_view__end__line" />
        </div>
        <div className="application_details__timeline_view__end__text__container">
          <p className="application_details__timeline_view__end__text">No more steps completed by the user</p>
        </div>
      </div>
    </TimelineItem>
  );
}

export default EndTimelineViewItem;
