import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

export const configSlice = createSlice({
  name: 'config',
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      const { configKey, configData } = action.payload;
      return { ...state, [configKey]: configData };
    },
  },
});

export const {
  updateConfig,
} = configSlice.actions;
export default configSlice.reducer;
