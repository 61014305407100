import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { get } from 'lodash';

function ManualAssignmentToolBar({ onDeSelectAll, children }) {
  const isSelectAll = useSelector((state) => state.data
    .selectHeadStateForApplicationsTable.headState.all);

  const selectedTransactionsForManualAssignment = Object.keys(useSelector((state) => get(state, 'data.selectedTransactionsForManualAssignment', {})));

  const transactionCountData = useSelector((state) => get(state, 'data.assignedTransactionCountData', {}));

  return (
    <div className="selectDialog_container">
      <p>
        {isSelectAll ? transactionCountData?.total
          : selectedTransactionsForManualAssignment?.length}
        {' '}
        Application(s) Selected
      </p>
      <br />
      <span className="divider-line" />
      <button
        type="button"
        className="clear_all_button"
        onClick={onDeSelectAll}
      >
        Deselect All
      </button>
      {children}
    </div>
  );
}

ManualAssignmentToolBar.propTypes = {
  onDeSelectAll: PropTypes.func.isRequired,
  children: PropTypes.element.isRequired,
};

export default ManualAssignmentToolBar;
