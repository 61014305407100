import axios from 'axios';
import { get } from 'lodash';

const fetchDataForCard = async ({
  appId, transactionId, requestId, endpoint,
}) => {
  const response = await axios({
    method: 'POST',
    url: `${process.env.REACT_APP_SERVER_URL}/api/v1/audit/dynamic-card-data`,
    headers: { appId },
    data: {
      transactionId,
      requestId,
      endpoint,
    },
  });
  return get(response, 'data.result', {});
};

export default fetchDataForCard;
