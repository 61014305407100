import React from 'react';
import PropTypes from 'prop-types';

import { ModuleBillingTable } from 'storybook-ui-components';
import { useSelector } from 'react-redux';
import { get } from 'lodash';
import Header from '../PricingInfo/Header';
import CommonSelect from '../../Common/Inputs/CommonSelect';
import { usageDownloadType } from '../../../containers/Common/Download/utils';
import CustomButton from '../../Common/Buttons/CustomButton';
import DownloadIcon from './DownloadIcon';

function ModuleBilling({
  isActive, buttonClick, fetchLogs, isLoading, currency, dates, handleAppIdChange, appId,
}) {
  // eslint-disable-next-line max-len
  const getPreviousMonthStartDate = (options) => (options.length >= 2 ? options[1].value : options[0].value);
  const currencySymbolMap = useSelector((state) => get(state, 's3Config.commonConfig.configData.currency', []));

  const { data, totalCost, appIdWiseUsageData } = useSelector((state) => state.pricing.moduleUsage);
  const tableData = appId === 'all' ? data : appIdWiseUsageData?.[appId]?.usageRows;
  const total = appId === 'all' ? totalCost : appIdWiseUsageData?.[appId]?.totalCost;

  const userAppIds = useSelector((state) => state.user.appIds);
  const appIdList = appId === 'all' ? Object.keys(userAppIds) : [appId];
  const appIdOptions = Object.keys(appIdWiseUsageData)
    ?.map((appIdValue) => ({ label: appIdValue, value: appIdValue }));
  appIdOptions.push({ label: 'All', value: 'all' });

  const fetchLogsForModuleBilling = () => fetchLogs(usageDownloadType);

  return (
    <>
      <Header headerText="Module Billing" />
      <div id="usage__header_filters">
        <div className="usage_filters">
          <CommonSelect
            options={dates}
            label="Choose a Month"
            isSelected={isActive === 'menu'}
            handleChange={(value) => {
              buttonClick('menu', value);
            }}
            initValue={getPreviousMonthStartDate(dates)}
            disabled={isLoading}
          />
          <CommonSelect
            options={appIdOptions}
            label="Select AppId"
            isSelected={isActive === 'menu'}
            handleChange={handleAppIdChange}
            disabled={isLoading}
            initValue="all"
          />
        </div>
        <CustomButton
          IconLeft={DownloadIcon}
          onClick={fetchLogsForModuleBilling}
        >
          Download Logs
        </CustomButton>
      </div>
      <ModuleBillingTable
        tableData={tableData}
        currency={currency}
        totalCost={total}
        appIdList={appIdList}
        isLoading={isLoading}
        credentialsType="STAGING & PRODUCTION"
        currencySymbolMap={currencySymbolMap}
      />
    </>
  );
}

ModuleBilling.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  isActive: PropTypes.string.isRequired,
  buttonClick: PropTypes.func.isRequired,
  fetchLogs: PropTypes.func.isRequired,
  currency: PropTypes.string.isRequired,
  dates: PropTypes.array.isRequired,
  handleAppIdChange: PropTypes.func.isRequired,
  appId: PropTypes.string.isRequired,
};

export default ModuleBilling;
