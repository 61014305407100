import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  isNil,
  get,
  isEmpty,
  toUpper,
} from 'lodash';
import { useSelector } from 'react-redux';
import { ToolTip } from 'clm-components';
import CommonSearchBar from '../../../Common/Inputs/CommonSearchBar';
import '../../../Common/Filter/Filter.scss';
import useGetUserPermissions from '../../../../Permissions/hooks';
import checkForManualAssignmentPermissions from '../../utils';
import { constants } from '../../../../config';
import searchIcon from '../../../../assests/icons/manualAssignmentSearchIcon.svg';

function SelectAssigneeFilterCard({
  onFilterAdd,
  availableFilterList,
  selectedFilterList,
  handleClose,
  cardSize,
  setCurrentSelectedFilter,
  currentAssignee,
}) {
  const [filterList, setFilterList] = useState([]);
  const [showCurrentUser, setShowCurrentUser] = useState(false);

  const user = useSelector((state) => get(state, 'user.email', ''));
  const { allowedPermissions } = useGetUserPermissions();
  const applicationManualReviewAssignment = useSelector((state) => get(state, 'config.applicationManualReviewAssignment', 'none'));

  useEffect(() => {
    if (availableFilterList) setFilterList(availableFilterList);
  }, [availableFilterList]);

  useEffect(() => () => setCurrentSelectedFilter(''), [setCurrentSelectedFilter]);

  const checkForAdminOrReviewerPermissions = () => {
    const isManualAssignmentToAll = checkForManualAssignmentPermissions(
      allowedPermissions,
      constants.PERMISSIONS_TO_ENABLE_MANUAL_ASSIGNMENT_TO_ALL,
      [{ key: applicationManualReviewAssignment, value: 'none' }],
    );

    const isManualAssignmentToSelf = checkForManualAssignmentPermissions(
      allowedPermissions,
      constants.PERMISSIONS_TO_ENABLE_MANUAL_ASSIGNMENT_TO_THEMSELVES,
      [{ key: applicationManualReviewAssignment, value: 'none' }],
    ) && currentAssignee && user === currentAssignee;

    return isManualAssignmentToAll || isManualAssignmentToSelf || false;
  };

  const handleOnClick = (filterValue, isDisabled) => {
    if (isDisabled) return;
    setCurrentSelectedFilter(filterValue);
    onFilterAdd(filterValue);
    handleClose();
  };

  const handleSearch = (value) => {
    if (!value) {
      setFilterList(availableFilterList);
      setShowCurrentUser(true);
      return;
    }
    const isUserMatched = user?.toLowerCase()?.includes(value?.toLowerCase());
    setShowCurrentUser(isUserMatched);

    const searchedFilterList = availableFilterList
      .map((group) => ({
        ...group,
        emails: group?.emails?.filter((email) => email.id.toLowerCase()
          ?.includes(value.toLowerCase())),
      }))
      .filter((group) => group.emails.length > 0);

    setFilterList(searchedFilterList);
  };

  const renderUserButton = (id, label, isDisabled) => (
    <button
      type="button"
      id={id}
      key={id}
      className={isDisabled ? 'disabled' : ''}
      onClick={() => handleOnClick(id, isDisabled)}
    >
      <span>
        <ToolTip
          title={
      (
        <>
          <span>{label}</span>
        </>
      )
    }
          placement="top-start"
        >
          {user === label ? (<span>{`You (${label})`}</span>) : (<span>{label}</span>) }
        </ToolTip>
      </span>
    </button>
  );

  return (
    <div id="filter_card_container_selectAssignee" className={`${cardSize}`}>
      <div id="filter_card_search_container">
        <CommonSearchBar
          id="selectAssignee_filtercard_search"
          handleSearch={handleSearch}
          placeholder="Search for Assignee email..."
          customSearchIcon={searchIcon}
        />
        <span className="divider-line-searchBar" />
      </div>

      {showCurrentUser && (
        <div id="filter_card_group_container_selectAssignee">
          <div id="filter_card_items_container_nowrap_selectAssignee">
            {renderUserButton(
              user,
              user,
              selectedFilterList?.includes(user),
            )}
          </div>
        </div>
      )}

      {!isNil(filterList) && filterList.length > 0 ? (
        filterList.map((group) => (
          checkForAdminOrReviewerPermissions() && !isEmpty(group?.role) ? (
            <div id="filter_card_group_container_selectAssignee" key={group?.role}>
              <div id="filter_card_items_container_nowrap_selectAssignee">
                <div id="filter_card_role_container">
                  <p>
                    {
                      toUpper(group?.role)
                    }
                  </p>
                </div>
                {group?.emails?.map((email) => (
                  renderUserButton(
                    email.id,
                    email.id,
                    selectedFilterList?.includes(email?.id),
                  )
                ))}
              </div>
            </div>
          ) : null
        ))
      ) : (
        <div id="filter_card_group_container" className="empty">
          <span>No Assignee found</span>
        </div>
      )}
    </div>
  );
}

SelectAssigneeFilterCard.defaultProps = {
  currentAssignee: null,
};

SelectAssigneeFilterCard.propTypes = {
  onFilterAdd: PropTypes.func.isRequired,
  cardSize: PropTypes.string.isRequired,
  availableFilterList: PropTypes.arrayOf(
    PropTypes.shape({
      role: PropTypes.string.isRequired,
      emails: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
        }),
      ).isRequired,
    }),
  ).isRequired,
  selectedFilterList: PropTypes.array.isRequired,
  handleClose: PropTypes.func.isRequired,
  setCurrentSelectedFilter: PropTypes.func.isRequired,
  currentAssignee: PropTypes.string,
};

export default SelectAssigneeFilterCard;
