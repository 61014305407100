import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, get } from 'lodash';
import Modal from '../../../../ManualAssignment/common/Modal/Modal';
import ReasonSelection from './ReasonSelection';
import REVIEW_DECISION_REASON_TYPES from '../../../../../constants/record';

function ReviewDecisionModal({
  isOpen,
  onClose,
  onSubmit,
  status,
  optionsConfig,
  selectedReviewDecisions,
  onDecisionSelect,
  onCustomInputChange,
}) {
  const statusAndHeaderDenoter = {
    manually_declined: 'declining',
    manually_approved: 'approving',
  };
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState(null);

  const options = get(optionsConfig, `${status}.reasonsList`, []);
  const isMultiSelect = get(optionsConfig, `${status}.reasonType`, REVIEW_DECISION_REASON_TYPES.SINGLE) !== REVIEW_DECISION_REASON_TYPES.SINGLE;
  const isMandatory = get(optionsConfig, `${status}.mandatory`, false);
  const allowCustomReason = get(optionsConfig, `${status}.allowCustomReason`, false);

  useEffect(() => {
    setShowAlert(false);
    setAlertMessage(null);
  }, [selectedReviewDecisions]);

  const onAlertClose = () => {
    setShowAlert(false);
    setAlertMessage(null);
  };

  if (isEmpty(optionsConfig)) {
    return null;
  }

  const validateAndSubmit = () => {
    const { customOption, selectedReasonsList } = selectedReviewDecisions;

    const validationType = isMultiSelect ? 'multiSelect' : 'singleSelect';

    switch (validationType) {
      case 'singleSelect':
        if (!customOption.selected && selectedReasonsList.length === 0 && isMandatory) {
          setShowAlert(true);
          setAlertMessage('Select any 1 option to approve the application.');
          return;
        }

        if (customOption.selected && allowCustomReason) {
          if (!customOption.value || customOption.value.trim() === '') {
            setShowAlert(true);
            setAlertMessage('"Other" option cannot be left empty.');
            return;
          }
          if (customOption.value.trim().length < 5) {
            setShowAlert(true);
            setAlertMessage('"Other" option must have at least 5 characters.');
            return;
          }
        }
        break;

      case 'multiSelect':
        if (selectedReasonsList.length === 0 && !customOption.selected && isMandatory) {
          setShowAlert(true);
          setAlertMessage('Select at least 1 reason to approve the application.');
          return;
        }

        if (customOption.selected && allowCustomReason) {
          if (!customOption.value || customOption.value.trim() === '') {
            setShowAlert(true);
            setAlertMessage('"Other" option cannot be left empty.');
            return;
          }
          if (customOption.value.trim().length <= 10) {
            setShowAlert(true);
            setAlertMessage('"Other" option must have more than 10 characters.');
            return;
          }
        }
        break;

      default:
        return;
    }

    let decisions = [...selectedReasonsList];

    if (customOption.selected && customOption.value) {
      decisions = [...decisions, customOption.value];
    }

    setShowAlert(false);
    setAlertMessage(null);
    onSubmit(decisions);
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={validateAndSubmit}
      header={`Pick a reason for ${statusAndHeaderDenoter[status]} this application`}
      id="reviewButtons_modal_reviewDecisions"
    >
      <ReasonSelection
        options={options}
        selectedReviewDecisions={selectedReviewDecisions}
        onDecisionSelect={onDecisionSelect}
        isMultiSelect={isMultiSelect}
        allowCustomReason={allowCustomReason}
        onCustomInputChange={onCustomInputChange}
        showAlert={showAlert}
        onAlertClose={onAlertClose}
        alertMessage={alertMessage}
      />
    </Modal>
  );
}

ReviewDecisionModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  status: PropTypes.string.isRequired,
  optionsConfig: PropTypes.object.isRequired,
  selectedReviewDecisions: PropTypes.array.isRequired,
  onDecisionSelect: PropTypes.func.isRequired,
  onCustomInputChange: PropTypes.func.isRequired,
};

export default ReviewDecisionModal;
