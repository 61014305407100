import React from 'react';
import PropTypes from 'prop-types';
import { startCase } from 'lodash';
import Modal from '../../../../ManualAssignment/common/Modal/Modal';

function ReAssignmentConfirmationModal({
  isOpen,
  onClose,
  onSubmit,
  status,
  reviewerEmail,
}) {
  const statusAndDenoterMap = {
    manually_declined: 'decline',
    manually_approved: 'approve',
  };

  const denoterAction = statusAndDenoterMap[status];

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={onSubmit}
      header={`Reassign & ${startCase(denoterAction)}`}
      id="Manual_assigment_modal_reviewButtons"
    >
      <div className="model-content-reviewButton">
        <p>
          {reviewerEmail ? (
            <>
              Please confirm your choice to
              {' '}
              {denoterAction}
              {' '}
              this application
              originally assigned to
              {' '}
              <b>{reviewerEmail}</b>
              .
            </>
          ) : (
            <>
              Please confirm your choice to
              {' '}
              {denoterAction}
              {' '}
              this application
              which is currently
              {' '}
              <b>Unassigned</b>
              .
            </>
          )}
        </p>
      </div>
    </Modal>
  );
}

ReAssignmentConfirmationModal.defaultProps = {
  reviewerEmail: null,
};

ReAssignmentConfirmationModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  status: PropTypes.string.isRequired,
  reviewerEmail: PropTypes.string,
};

export default ReAssignmentConfirmationModal;
