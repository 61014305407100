import React from 'react';
import {
  TimelineContent, TimelineItem,
} from '@mui/lab';

import PropTypes from 'prop-types';

function UserResumedTimelineViewItem({ createdAt, sdkType, sdkVersion }) {
  return (
    <TimelineItem data-date={createdAt} className="application_details__timeline_view__resume__container">
      <TimelineContent className="application_details__timeline_view__timeline_content__resume">
        <div className="application_details__timeline_view__timeline_content__resume__content">
          <p>User resumed</p>
          <hr />
          <p>
            SDK:
            {' '}
            <span>{sdkType || '-'}</span>
            {' '}
            SDK Version:
            {' '}
            <span>{sdkVersion || '-'}</span>
          </p>
        </div>
      </TimelineContent>
    </TimelineItem>
  );
}

UserResumedTimelineViewItem.propTypes = {
  createdAt: PropTypes.string.isRequired,
  sdkType: PropTypes.string.isRequired,
  sdkVersion: PropTypes.string.isRequired,
};

export default UserResumedTimelineViewItem;
