import React from 'react';
import PropTypes from 'prop-types';
import './Modal.scss';

const Modal = ({
  isOpen,
  onClose,
  children,
  onSubmit,
  header,
  id,
}) => {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay" id={id} onClick={onClose} aria-hidden>
      <div
        className="modal-content"
        onClick={(e) => e.stopPropagation()}
        aria-hidden
      >
        <div className="modal-header">
          <h3>{header}</h3>
          <button className="close-button" onClick={onClose} type="button" aria-label="Close">&times;</button>
        </div>
        <div className="modal-body">
          {children}
        </div>
        <div className="modal-footer">
          <button className="cancel-button" onClick={onClose} type="button">Cancel</button>
          <button className="application__subheading_button" onClick={onSubmit} type="button">Confirm</button>
        </div>
      </div>
    </div>
  );
};

Modal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.element.isRequired,
  onSubmit: PropTypes.func.isRequired,
  header: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
};

export default Modal;
