import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { last, get } from 'lodash';
import { Spinner } from 'clm-components';
import { useSelector } from 'react-redux';
import RightPanel from './RightPanel/RightPanel';
import RecordBody from './Body/RecordBody';
import LeftPanel from './LeftPanel/LeftPanel';
import ReviewCard from './RightPanel/ReviewCard/ReviewCard';
import CommentBody from './RightPanel/Comment/CommentBody';
import { summaryModuleId } from './RecordUtils';
import { scrollElementIntoViewById } from '../../utils/scrollUtils';
import SubPanelWrapper from './RightPanel/SubPanelWrapper';
import ReviewTagsCard from './RightPanel/ReviewTagsCard/ReviewTagsCard';
import ReviewButtons from './RightPanel/ReviewButtons/ReviewButtons';
import TimelineView from './Body/TimelineView';
import ManualAssignmentCard from '../ManualAssignment/Record/cards/ManualAssignmentCard';
import Modal from '../ManualAssignment/common/Modal/Modal';
import ModalBody from '../ManualAssignment/Record/ModalBody';
import { constants } from '../../config';

function RecordContent({
  loading,
  comments,
  addNewComment,
  updateApplicationStatus,
  showTimelineView,
  workflowModulesData,
  onChangeAssigneeModalClose,
  selectedAssignee,
  onSelectAssignee,
  onAssigneeChange,
  onReAssignment,
}) {
  const [selectedModule, setSelectedModule] = useState(summaryModuleId);
  const [isAutoScrolling, setIsAutoScrolling] = useState(false);
  const applicationManualReviewAssignment = useSelector((state) => get(state, 'config.applicationManualReviewAssignment', 'none'));

  const transactionData = useSelector((state) => get(state, 'appDetails.transactionData', {}));

  const { status } = transactionData;

  const handleModuleChange = async (moduleId) => {
    setSelectedModule(moduleId);
    setIsAutoScrolling(true);
    await scrollElementIntoViewById('record__content', moduleId);
    setIsAutoScrolling(false);
  };

  const showManualAssignmentCard = () => {
    if (applicationManualReviewAssignment !== 'none' && constants.STATUSES_TO_SHOW_CHANGE_ASSIGNEE_CARD.includes(status)) {
      return true;
    }
    return false;
  };

  const scrollHandler = (event) => {
    if (isAutoScrolling) return;
    // topMargin refers to the margin of headers which is 66px;
    // bottomMargin refers to the margin below the body
    // TODO: find a way to sync and maintain both at the same place
    const topMargin = 56;
    const bottomMargin = 20;
    const bodyChildrenDivArray = Array.from(
      document.querySelectorAll('#record__body > div'),
    );
    let selectedModuleId = selectedModule;
    // Check for the first child which satisfies the condition
    bodyChildrenDivArray.every((child) => {
      if (
        event.target.scrollTop + topMargin >= child?.offsetTop
        && event.target.scrollTop + topMargin <= child?.offsetTop + (child?.offsetHeight / 2)
          && event.target.scrollTop + topMargin < child?.offsetTop + child?.offsetHeight
      ) {
        selectedModuleId = child.id;
        return false;
      }
      return true;
    });

    // Check if last element is fully visible
    const lastElement = last(bodyChildrenDivArray);
    if (event.target.scrollTop + event.target.offsetHeight + bottomMargin + topMargin
        >= lastElement?.offsetTop + lastElement?.offsetHeight
    ) {
      selectedModuleId = lastElement.id;
    }
    setSelectedModule(selectedModuleId);
  };

  if (loading) {
    return <div className="loader"><Spinner /></div>;
  }

  return (
    <div
      id="record__content"
      onScroll={scrollHandler}
      className={`${showTimelineView ? 'timeline' : ''}`}
    >

      {showTimelineView ? (
        <>
          <div id="current-date" className="hidden" />
          <TimelineView
            workflowModulesData={workflowModulesData}
            applicationStatus={transactionData.status}
          />
        </>
      ) : (
        <>
          <LeftPanel
            selectedModule={selectedModule}
            handleModuleChange={handleModuleChange}
          />
          <RecordBody
            transactionData={transactionData}
          />
        </>
      )}
      <RightPanel>
        { showManualAssignmentCard() && (
          <>
            <ManualAssignmentCard
              onFilterAdd={onSelectAssignee}
              selectedAssignee={selectedAssignee}
              currentAssignee={transactionData.reviewerEmail}
            />
            <Modal
              isOpen={selectedAssignee}
              onClose={onChangeAssigneeModalClose}
              onSubmit={onAssigneeChange}
              header="Confirm Application Assignment"
              id="Manual_assigment_modal_recordcontent"
            >
              <ModalBody assigneeEmail={selectedAssignee} />
            </Modal>
          </>
        )}
        <ReviewCard
          transactionData={transactionData}
          selectedModule={selectedModule}
          handleModuleChange={handleModuleChange}
        />
        <SubPanelWrapper>
          <ReviewTagsCard />
          <CommentBody
            comments={comments}
            addNewComment={addNewComment}
          />
          <ReviewButtons
            onChangeAssingeeForReAssignment={onSelectAssignee}
            onReAssignment={onReAssignment}
            updateApplicationStatus={updateApplicationStatus}
            applicationStatus={transactionData.status}
            reviewerEmail={transactionData.reviewerEmail}
          />
        </SubPanelWrapper>
      </RightPanel>
    </div>
  );
}

RecordContent.defaultProps = {
  onChangeAssigneeModalClose: () => {},
  onSelectAssignee: () => {},
  onAssigneeChange: () => {},
  selectedAssignee: '',
};

RecordContent.propTypes = {
  loading: PropTypes.bool.isRequired,
  comments: PropTypes.arrayOf(PropTypes.any).isRequired,
  updateApplicationStatus: PropTypes.func.isRequired,
  addNewComment: PropTypes.func.isRequired,
  showTimelineView: PropTypes.bool.isRequired,
  workflowModulesData: PropTypes.array.isRequired,
  onChangeAssigneeModalClose: PropTypes.func,
  onSelectAssignee: PropTypes.func,
  onAssigneeChange: PropTypes.func,
  selectedAssignee: PropTypes.string,
  onReAssignment: PropTypes.func.isRequired,
};

export default RecordContent;
