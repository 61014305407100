import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import debounce from 'lodash.debounce';
import { Spinner } from 'clm-components';
import { isNull } from 'lodash';
import SearchIcon from '../../../assests/icons/search.svg';
import { updateFocussedInputs } from '../../../reducers/focussedInputs';
import './Inputs.scss';

function CommonSearchBar({
  id,
  initValue,
  handleSearch,
  placeholder,
  onFocus,
  isIconVisible,
  isInputVisible,
  isDebounce,
  debounceTime,
  searchIconStyle,
  customSearchIcon,
}) {
  const [isSearchFocussed, setIsSearchFocussed] = useState(false);
  const [value, setValue] = useState('');
  const [inputValue, setInputValue] = useState('');
  const [isInputOpen, setIsInputOpen] = useState(true);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const handleFocus = (isFocussed) => {
    if (onFocus) onFocus(isFocussed);
    setIsSearchFocussed(isFocussed);
    dispatch(updateFocussedInputs({ inputName: 'AppInput', isFocussed }));
  };

  const debounceHandleChange = debounce((event) => {
    setValue(event.target.value);
    setLoading(false);
  }, debounceTime);

  const handleChange = (event) => {
    setInputValue(event.target.value);
    if (isDebounce) {
      setLoading(true);
      debounceHandleChange(event);
    } else {
      setValue(event.target.value);
    }
  };

  const onIconClick = () => {
    setIsInputOpen(!isInputOpen);
  };

  const handleIcon = () => {
    if (!isIconVisible) {
      return <></>;
    }
    if (loading) {
      return <div className="search_loader_wrapper"><Spinner /></div>;
    }
    return <img src={isNull(customSearchIcon) ? SearchIcon : customSearchIcon} alt="s" onClick={onIconClick} style={searchIconStyle} aria-hidden />;
  };

  useEffect(() => {
    if (isInputVisible !== undefined) setIsInputOpen(isInputVisible);
  }, [isInputVisible]);

  useEffect(() => {
    handleSearch(value);
  }, [value]);

  useEffect(() => {
    if (initValue) {
      setValue(initValue);
      setInputValue(initValue);
    }
  }, [initValue]);

  useEffect(() => {
    dispatch(updateFocussedInputs({ inputName: 'AppInput', isSearchFocussed }));
    return () => {
      dispatch(updateFocussedInputs({ inputName: 'AppInput', isFocussed: false }));
    };
  }, []);

  return (
    <div id={id} className={`common__searchbar ${isSearchFocussed ? 'focus' : ''}`}>
      {handleIcon()}
      <input
        data-hj-suppress
        placeholder={placeholder}
        onFocus={() => handleFocus(true)}
        onBlur={() => handleFocus(false)}
        onChange={handleChange}
        className={`${!isInputOpen && 'close'}`}
        value={inputValue}
      />
    </div>
  );
}

CommonSearchBar.defaultProps = {
  id: '',
  initValue: '',
  placeholder: 'Search',
  onFocus: null,
  isIconVisible: true,
  isInputVisible: true,
  isDebounce: false,
  debounceTime: 1000,
  searchIconStyle: {},
  customSearchIcon: null,
};

CommonSearchBar.propTypes = {
  id: PropTypes.string,
  initValue: PropTypes.string,
  placeholder: PropTypes.string,
  onFocus: PropTypes.func,
  isIconVisible: PropTypes.bool,
  isInputVisible: PropTypes.bool,
  handleSearch: PropTypes.func.isRequired,
  isDebounce: PropTypes.bool,
  debounceTime: PropTypes.number,
  searchIconStyle: PropTypes.object,
  customSearchIcon: PropTypes.element,
};

export default CommonSearchBar;
