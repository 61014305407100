import axios from 'axios';

const axiosInstance = axios.create({
  withCredentials: false,
});

export const getFilterConfigApi = () => axiosInstance.get(process.env.REACT_APP_S3_FILTER_URL);

export const getUsageConfigApi = () => axiosInstance.get(process.env.REACT_APP_S3_USAGE_CONFIG_URL);

export const getFlagConfigApi = () => axiosInstance.get(process.env.REACT_APP_S3_FLAG_URL);

export const getCommonConfigApi = () => axiosInstance.get(
  process.env.REACT_APP_S3_PORTAL_COMMON_CONFIG_URL,
);

export const getTransactionMetadataColumnsConfigApi = () => axiosInstance
  .get(process.env.REACT_APP_S3_TRANSACTION_METADATA_COLUMNS_URL);
