import React from 'react';
import PropTypes from 'prop-types';

function SelectAssigneeButton({
  selectedFilter, isActive, handleClick,
}) {
  return (
    <button
      type="button"
      id="assign_applications_button"
      className={isActive ? 'active application__subheading_button' : 'application__subheading_button'}
      onClick={handleClick}
    >
      <span>{isActive ? (selectedFilter || 'Assign Applications') : 'Assign Applications'}</span>
    </button>
  );
}

SelectAssigneeButton.propTypes = {
  handleClick: PropTypes.func.isRequired,
  isActive: PropTypes.bool.isRequired,
  selectedFilter: PropTypes.string,
};

SelectAssigneeButton.defaultProps = {
  selectedFilter: '',
};

export default SelectAssigneeButton;
