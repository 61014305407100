// converts number of attempts to correct format

import {
  join, slice, last, isString, split, entries, get,
  isNull, isUndefined, isObject, isArray, isEmpty, isNil,
  toNumber, isFinite,
} from 'lodash';
import * as Sentry from '@sentry/react';

// to be shown on dashboard eg: if i=1, it should return 1st
const ordinalSuffix = (i) => {
  const j = i % 10;
  const k = i % 100; // for numbers higher than 100
  if (j === 1 && k !== 11) {
    return `${i}st`;
  }
  if (j === 2 && k !== 12) {
    return `${i}nd`;
  }
  if (j === 3 && k !== 13) {
    return `${i}rd`;
  }
  return `${i}th`;
};

const moduleToImageTagMap = {
  'Selfie Verification': 'selfie',
  'ID Card Verification - Front Side': 'id front',
  'ID Card Verification - Back Side': 'id back',
};

export const moduleToImageTag = (module) => {
  if (module in moduleToImageTagMap) {
    return moduleToImageTagMap[module];
  }
  return 'image';
};

export const arrayToCommaSeperatedString = (array) => {
  if (array.length === 0) return '';
  if (array.length === 1) return last(array);
  return `${join(slice(array, 0, array.length - 1), ', ')} and ${last(array, -1)}`;
};

export const getDataFromURLParams = (queryString) => {
  if (!isString(queryString)) return {};
  const searchParams = new URLSearchParams(queryString);
  const searchParamsKeys = [...searchParams.keys()];
  const data = {};
  searchParamsKeys.forEach((key) => {
    data[key] = searchParams.get(key);
  });
  return data;
};

export const isValidHttpUrl = (string) => {
  let url;

  try {
    url = new URL(string);
  } catch (error) {
    Sentry.captureException(error);
    return false;
  }

  return url.protocol === 'http:' || url.protocol === 'https:';
};

export const getUrlMetadata = (url) => {
  const { pathname } = new URL(url);
  const splitUrlParts = pathname.split('/');
  const numberOfParts = splitUrlParts.length;
  const requestId = splitUrlParts[numberOfParts - 2]; // requestId will be the second last value
  const fileName = splitUrlParts[numberOfParts - 1]; // Since fileName will be the last value
  return { requestId, fileName };
};

export const isValidURL = (string) => {
  try {
    // eslint-disable-next-line no-new
    new URL(string);
    return true;
  } catch (error) {
    return false;
  }
};

export const getProtocolFromURL = (string) => {
  try {
    return new URL(string).protocol;
  } catch (error) {
    return null;
  }
};

export const constructSearchParamsString = (searchParams) => entries(searchParams).map(([key, value]) => `${key}=${value}`).join('&');

export const reconstructRedirectURL = ({ redirectURL, additionalSearchParams }) => {
  const urlObject = new URL(redirectURL);
  let reconstructedRedirectURL = `${urlObject.origin}${urlObject.pathname}`;
  if (urlObject.search) {
    reconstructedRedirectURL = `${reconstructedRedirectURL}${urlObject.search}&${constructSearchParamsString(additionalSearchParams)}`;
  } else if (additionalSearchParams) {
    reconstructedRedirectURL = `${reconstructedRedirectURL}?${constructSearchParamsString(additionalSearchParams)}`;
  }
  if (urlObject.hash) {
    reconstructedRedirectURL = `${reconstructedRedirectURL}${urlObject.hash}`;
  }
  return reconstructedRedirectURL;
};

export const getRedirectURLFromQueryParams = (queryParams) => {
  if (queryParams instanceof URLSearchParams) {
    return queryParams?.get('redirect') || queryParams?.get('redirectUrl') || queryParams?.get('url');
  }
  return get(queryParams, 'redirect') || get(queryParams, 'redirectUrl') || get(queryParams, 'url');
};

export const isAuditPortalURL = (string) => {
  try {
    const { hostname } = new URL(string);
    if (!hostname.includes('audit-portal')) throw new Error('Not an Audit Portal URL');
    return true;
  } catch (error) {
    return false;
  }
};

export const getFileExtentionFromUrl = (url) => {
  if (!isValidURL(url)) {
    return null;
  }

  try {
    const urlObject = new URL(url);
    const pathName = urlObject.pathname;
    const fileName = last(split(pathName, '/'));
    const fileExtension = last(split(fileName, '.'));
    return fileExtension;
  } catch (err) {
    return null;
  }
};

export const timeStringToSeconds = (timestamp) => {
  const [hours, minutes, seconds] = timestamp.split(':');
  const totalSeconds = (parseInt(hours, 10) * 60 * 60)
    + (parseInt(minutes, 10) * 60) + parseInt(seconds, 10);
  return totalSeconds;
};

export const isNullOrUndefined = (value) => isNull(value) || isUndefined(value);

export const isNullOrUndefinedOrEmptyString = (value) => (
  isNull(value) || isUndefined(value) || (isString(value) && value.length === 0)
);

export const isNonEmptyObject = (
  object,
) => object && isObject(object) && !isArray(object) && !isEmpty(object);

export const isNonEmptyArray = (array) => array && isArray(array) && array.length > 0;

export const isEmailValid = (email) => {
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  if (email.trim() === '' || !emailRegex.test(email.toLocaleLowerCase().trim())) {
    return false;
  }
  return true;
};

export const checkPasswordValidity = (password) => {
  const isLengthValid = password.length >= 8 && password.length <= 20;
  const isLowerCaseValid = /[a-z]/.test(password);
  const isUpperCaseValid = /[A-Z]/.test(password);
  const isNumericValid = /\d/.test(password);
  const isSpecialCharValid = /[\W_]/.test(password);

  return ({
    isLengthValid,
    isLowerCaseValid,
    isUpperCaseValid,
    isNumericValid,
    isSpecialCharValid,
  });
};

export const isNumber = (value) => isFinite(toNumber(value));

export const hasRequiredPermissions = (availablePermissions, permissionsArrayToCheck) => {
  if (isNil(availablePermissions) || isEmpty(availablePermissions)
      || isNil(permissionsArrayToCheck) || isEmpty(permissionsArrayToCheck)) {
    return false;
  }

  const permissionCheck = permissionsArrayToCheck.some((permission) => {
    const trimmedPermission = permission.trim().toLowerCase();
    return availablePermissions.some((availablePermission) => {
      if (availablePermission === '*' || availablePermission === trimmedPermission) {
        return true;
      }
      return availablePermission.toLowerCase() === trimmedPermission;
    });
  });

  return permissionCheck;
};

export const validateConditions = (validationConditions) => {
  if (isNil(validationConditions) || isEmpty(validationConditions)) {
    return false;
  }

  return validationConditions.every(({ key, value }) => key !== value);
};

export default ordinalSuffix;
