import React from 'react';
import PropTypes from 'prop-types';
import checkboxCheckedState from '../../../assests/icons/checkboxCheckedState.svg';
import checkboxDisabledState from '../../../assests/icons/checkboxDisabledState.svg';
import checkboxNormalState from '../../../assests/icons/checkboxNormalState.svg';

function CommonCheckbox({ checked, disabled, onChange }) {
  const handleClick = (e) => {
    e.stopPropagation();
    if (!disabled && onChange) {
      onChange(!checked);
    }
  };

  let src;
  let alt;

  if (checked) {
    src = checkboxCheckedState;
    alt = 'Checked';
  } else if (disabled) {
    src = checkboxDisabledState;
    alt = 'Disabled';
  } else {
    src = checkboxNormalState;
    alt = 'Unchecked';
  }

  return (
    <div
      role="checkbox"
      aria-checked={checked}
      tabIndex={0}
      className={`checkbox-container ${disabled ? 'disabled' : ''}`}
      onClick={handleClick}
      onKeyPress={(e) => {
        if (!disabled && (e.key === 'Enter' || e.key === ' ')) {
          handleClick(e);
        }
      }}
    >
      <img src={src} alt={alt} width={16} height={16} />
    </div>
  );
}

CommonCheckbox.propTypes = {
  checked: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default CommonCheckbox;
