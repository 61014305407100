import React from 'react';
import PropTypes from 'prop-types';
import { ToolTip } from 'clm-components';

function ConditionalToolTip({ children, title, ...rest }) {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return title ? <ToolTip title={title} {...rest}>{children}</ToolTip> : children;
}

ConditionalToolTip.defaultProps = {
  title: '',
};

ConditionalToolTip.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  title: PropTypes.string,
};

export default ConditionalToolTip;
