import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';

import * as Sentry from '@sentry/react';
import { useNavigate } from 'react-router-dom';
import { Drawer as DrawerStoryBookComponent, getDrawerApplicationPagePath, getHiddenAndDisabledDrawerKeysFromPermissions } from 'storybook-ui-components';
import './Main.scss';
import { get, reduce, snakeCase } from 'lodash';
import { updateDocToken } from '../../reducers/onboardData';
import AlertCode from '../../constants/alertCodes';
import useShowErrorAlert, { useLogoutAction, useFormatAnalyticsData } from '../../utils/lib';
import rudderstackEvents from '../../constants/rudderstackEventNames';
import useGetUserPermissions from '../../Permissions/hooks';
import { DOCUMENTATION_PORTAL_URL } from '../../config';
import screenNames from '../../constants/screenNames';
import errorCode from '../../constants/errorCode';
import { isNullOrUndefinedOrEmptyString } from '../../utils/helpers';

const Drawer = ({ activeNav, activeSubNav, pathname }) => {
  const email = useSelector((state) => state.user.email);
  const clientId = useSelector((state) => state.user.clientId);
  const showErrorAlert = useShowErrorAlert();
  const formatAnalyticsData = useFormatAnalyticsData();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const logout = useLogoutAction();
  const docToken = useSelector((state) => state.onboard.docToken);
  const { allowedPermissions, disallowedPermissions } = useGetUserPermissions();
  const [instatusPageMenuOptions, setinstatusPageMenuOptions] = useState([]);
  const [instatusDrawerFunctions, setInstatusDrawerFunction] = useState({});

  const applicationsTab = getDrawerApplicationPagePath(
    allowedPermissions,
    disallowedPermissions,
    pathname,
  );

  const { hiddenNavKeys, disabledNavKeys } = getHiddenAndDisabledDrawerKeysFromPermissions(
    disallowedPermissions,
  );

  const fetchDocToken = async () => {
    try {
      const userRes = await axios({
        method: 'GET',
        url: `${process.env.REACT_APP_SERVER_URL}/api/v1/onboard/docToken`,
      });
      dispatch(updateDocToken(userRes.data.result));
    } catch (error) {
      Sentry.captureException(`${errorCode.DRAWER_DOC_TOKEN_ERROR} - ${error}`, {
        extra: {
          errorMessage: 'Error Fetching Documentation Token',
        },
      });
      showErrorAlert({ error, message: AlertCode.FETCH_DOC_TOKEN });
    }
  };

  const fetchStatusPageLink = async () => {
    try {
      const instatusLinkRes = await axios({
        method: 'GET',
        url: `${process.env.REACT_APP_SERVER_URL}/api/v2/external/instatus/link`,
      });
      const secureLink = get(instatusLinkRes, 'data.result.secureLinkMap');

      const result = reduce(Object.keys(secureLink), (acc, region) => {
        const pageLink = get(secureLink, `${region}.link`);
        const healthPageFunctionName = `${region}APIHealthPage`;
        acc.apiHealthFunctions[healthPageFunctionName] = () => {
          if (isNullOrUndefinedOrEmptyString(pageLink)) {
            Sentry.captureException(`${errorCode.DRAWER_EMPTY_INSTATUS_LINK_CLICK}}`, {
              extra: {
                errorMessage: `No health page link found for ${region}. Please reload the page and try again. If the issue still persists, please reach out to HyperVerge support.`,
              },
            });
            showErrorAlert({ error: {}, message: AlertCode.FETCH_INSTATUS_PAGE_LINK });
            return;
          }
          formatAnalyticsData(
            email, clientId, `${snakeCase(healthPageFunctionName)}_click`, screenNames.DRAWER,
          );
          window.open(pageLink);
        };
        acc.collapsableMenuItems.push({
          key: region,
          id: region,
          text: get(secureLink, `${region}.label`),
          external: true,
          disabled: false,
          icon: <img src="" alt="" />,
          onClickFunctionName: healthPageFunctionName,
        });
        return acc;
      }, { apiHealthFunctions: {}, collapsableMenuItems: [] });
      setInstatusDrawerFunction(result.apiHealthFunctions);
      setinstatusPageMenuOptions(result.collapsableMenuItems);
    } catch (error) {
      Sentry.captureException(`${errorCode.DRAWER_INSTATUS_LINK_FETCH_ERROR} - ${error}`, {
        extra: {
          errorMessage: 'Error Fetching Instatus Page Link',
        },
      });
      showErrorAlert({ error, message: AlertCode.FETCH_INSTATUS_PAGE_LINK });
    }
  };

  useEffect(() => {
    fetchDocToken();
    fetchStatusPageLink();
  }, []);

  const drawerFunctions = {
    home: () => {
      formatAnalyticsData(
        email, clientId, rudderstackEvents.DASHBOARD_NAVBAR_HOME_CLICK, screenNames.DRAWER,
      );
      navigate('/home');
    },
    applications: () => {
      formatAnalyticsData(
        email, clientId, rudderstackEvents.DASHBOARD_NAVBAR_APPLICATIONS_CLICK, screenNames.DRAWER,
      );
      navigate(applicationsTab);
    },
    workflows: () => {
      formatAnalyticsData(
        email, clientId, rudderstackEvents.DASHBOARD_WORKFLOWS_LAUNCH, screenNames.DRAWER,
      );
      window.location.replace(`${process.env.REACT_APP_WORKFLOW_APP_URL}`);
    },
    credentials: () => {
      navigate('/credentials');
      formatAnalyticsData(
        email, clientId, rudderstackEvents.DASHBOARD_NAVBAR_DEVHUB_CLICK, screenNames.DRAWER,
      );
      formatAnalyticsData(
        email, clientId, rudderstackEvents.DASHBOARD_NAVBAR_CREDENTIALS_CLICK, screenNames.DRAWER,
      );
    },
    logout: async () => {
      formatAnalyticsData(
        email, clientId, rudderstackEvents.DASHBOARD_NAVBAR_LOGOUT_CLICK, screenNames.DRAWER,
      );
      await logout();
      dispatch({ type: 'RESET' });
    },
    usage: () => {
      navigate('/usage');
      formatAnalyticsData(
        email, clientId, rudderstackEvents.DASHBOARD_NAVBAR_USAGE_CLICK, screenNames.DRAWER,
      );
    },
    pricingInfo: () => {
      navigate('/pricing');
      formatAnalyticsData(
        email, clientId, rudderstackEvents.DASHBOARD_NAVBAR_PRICING_CLICK, screenNames.DRAWER,
      );
    },
    documentation: () => {
      formatAnalyticsData(
        email, clientId, rudderstackEvents.DASHBOARD_NAVBAR_DOCUMENTATION_CLICK, screenNames.DRAWER,
      );
      window.open(`${DOCUMENTATION_PORTAL_URL}/?jwt=${docToken}`);
    },
    users: () => {
      formatAnalyticsData(
        email, clientId, rudderstackEvents.DASHBOARD_NAVBAR_USER_CLICK, screenNames.DRAWER,
      );
      window.location.replace(`${process.env.REACT_APP_USERS_APP_URL}`);
    },
    businessMetrics: () => window.location.replace(process.env.REACT_APP_ANALYTICS_APP_URL),
    stepwiseAnalytics: () => window.location.replace(`${process.env.REACT_APP_ANALYTICS_APP_URL}/stepwise`),
    ...instatusDrawerFunctions,
  };

  const subDrawerCollapsibleMenuItems = {
    drawer__menu_dev_hub_monitor_api_health: instatusPageMenuOptions,
  };

  return (
    <DrawerStoryBookComponent
      onClickFunctions={drawerFunctions}
      activeNav={activeNav}
      activeSubNav={activeSubNav}
      hiddenNavKeys={hiddenNavKeys}
      disabledNavKeys={disabledNavKeys}
      subDrawerCollapsibleMenuItems={subDrawerCollapsibleMenuItems}
    />
  );
};

Drawer.propTypes = {
  activeNav: PropTypes.string.isRequired,
  activeSubNav: PropTypes.string.isRequired,
  pathname: PropTypes.string.isRequired,
};

export default Drawer;
